// @ts-nocheck
import React from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core";

import "@brainhubeu/react-carousel/lib/style.css";
import OrigButton from "./homepage.gobutton";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    summary: {
      background: "#eeeeee",
      width: "100%",
      minHeight: "200px",
      paddingBottom: "25px",
    },
    packageWrapper: {
      display: "block",
      flexDirection: "row",
      justifyContent: "space-around",
      flexWrap: "wrap",
      alignItems: "center",
      maxWidth: "var(--max-width)",
      "@media (min-width: 855px)": {
        display: "flex",
        marginLeft: "auto",
        marginRight: "auto",
      },
    },
    package: {
      marginLeft: "auto",
      marginRight: "auto",
      marginTop: "00px",
      marginBottom: "0px",
      minHeight: "180px",
      borderRadius: "5px",
      "@media (min-width: 855px)": {
        marginLeft: "10px",
        marginRight: "10px",
        flex: "1 1 260px",
        minWidth: "350px",
      },
    },
    content: {
      backgroundImage: 'url("/painted_patch_rect_01.png")',
      backgroundSize: "100% 100%",
      marginLeft: "auto",
      marginRight: "auto",
      flex: "1 1 240px",

      padding: "30px 55px 25px 55px",
      "@media (min-width: 855px)": {
        flex: "1 1 280px",
        maxWidth: "500px",
      },
    },
    whyHeader: {
      fontFamily: "var(--font-header)",
      fontWeight: "bold",
      fontSize: "30px",
      lineHeight: "150%",
      paddingTop: "50px",
      marginBottom: "40px",
      textAlign: "center",
      paddingRight: "20px",
      color: "var(--theme-color)",
    },
    successHeader: {
      fontFamily: "var(--font-header)",
      fontWeight: "bold",
      color: "var(--theme-color)",
      fontSize: "18px",
      lineHeight: 1.5,
      paddingTop: "15px",
      marginBottom: "10px",
    },
    successText: {
      fontFamily: "var(--font-normal)",
      fontSize: "18px",
      lineHeight: "150%",
      marginBottom: "10px",
    },
    avatarFlex: {
      display: "flex",
    },
    avatarDiv: {
      flex: "0 0 60px",
    },
    signatureDiv: {
      flex: "1 1 auto",
      paddingBottom: "15px",
    },
    signatureName: {
      fontFamily: "var(--font-medium)",
      fontSize: "18px",
      lineHeight: 1.5,
    },
    signaturePost: {
      fontFamily: "var(--font-normal)",
      fontSize: "18px",
      lineHeight: 1.5,
      marginBottom: "10px",
      color: "#898989",
    },
    successes: {
      display: "flex",
      flexWrap: "wrap",
      maxWidth: "var(--max-width)",
      marginLeft: "auto",
      marginRight: "auto",
    },
  })
);

const Successes = (props: any) => {
  const classes = useStyles();
  const { tx } = props;

  return (
    <React.Fragment>
      <div className={classes.summary}>
        <div className={classes.whyHeader}>
          {tx("capo2", "Don't just take our word for it")}
        </div>
        <div className={classes.successes}>
          <div className={classes.package}>
            <div className={classes.content}>
              <div className={classes.successHeader}>
                {tx("rapo3as", "Really professional!")}
              </div>
              <div className={classes.successText}>
                {tx(
                  "rapo4a",
                  "I just looked at my newly received WizzWords app."
                )}{" "}
                {tx("rapo5a", "This is incredibly professional!!!!!")}{" "}
                {tx(
                  "rapoiioisd",
                  "Thanks a lot for the chance, it's a super app, I can have now no excusues not to learn. :)))))"
                )}
              </div>
              <div className={classes.avatarFlex}>
                <div className={classes.signatureDiv}>
                  <div className={classes.signatureName}>K.V.</div>
                </div>
              </div>
            </div>
          </div>
          <div className={classes.package}>
            <div className={classes.content}>
              <div className={classes.successHeader}>
                {tx("rapo8", "I can only recommend it")}
              </div>
              <div className={classes.successText}>
                {tx(
                  "rapo9as",
                  "I can only recommend this super app to everybody, it's a gift for its price!"
                )}{" "}
                {tx(
                  "asdfasdw",
                  "I downloaded, opened it, and I was amazed how complex, how user friendly and logical it is. With this program you easily study in a playful way."
                )}{" "}
                {tx("awerzxcv", "Great!")}
              </div>
              <div className={classes.avatarFlex}>
                <div className={classes.signatureDiv}>
                  <div className={classes.signatureName}>F.B.</div>
                </div>
              </div>
            </div>
          </div>
          <div className={classes.package}>
            <div className={classes.content}>
              <div className={classes.successHeader}>
                {tx("rapo12", "Exceeds expectations")}
              </div>
              <div className={classes.successText}>
                {tx(
                  "rapo13as",
                  "I downloaded WizzWords and I've already used it."
                )}{" "}
                {tx(
                  "rapo14wewe",
                  "It really exceeded my expectations. All combined it is is a very detailed and well designed application, that's worth more than its price, and complements beautifully any other level of English teaching course."
                )}
              </div>
              <div className={classes.avatarFlex}>
                <div className={classes.signatureDiv}>
                  <div className={classes.signatureName}>N.A.</div>
                </div>
              </div>
            </div>
          </div>
          <div className={classes.package}>
            <div className={classes.content}>
              <div className={classes.successHeader}>
                {tx("rapo12aasda", "Fantastic!")}
              </div>
              <div className={classes.successText}>
                {tx(
                  "rapo13asas",
                  "I simply can't find a fitting adjective, as ”Fantastic” is too litle for all those ways you can practice English phrases with this app."
                )}{" "}
              </div>
              <div className={classes.avatarFlex}>
                <div className={classes.signatureDiv}>
                  <div className={classes.signatureName}>H.ZS.</div>
                </div>
              </div>
            </div>
          </div>
          <div className={classes.package}>
            <div className={classes.content}>
              <div className={classes.successHeader}>
                {tx("rapo12we", "Good decision")}
              </div>
              <div className={classes.successText}>
                {tx(
                  "rapo13asas1",
                  "It's hard to summarize my enthusiasm in a few sentences! It was one of my better decisions to buy WizzWords!"
                )}{" "}
                {tx(
                  "rapo13asas12",
                  "Easy to understand, well designed, fun. It gives much more than just learning words!"
                )}{" "}
              </div>
              <div className={classes.avatarFlex}>
                <div className={classes.signatureDiv}>
                  <div className={classes.signatureName}>K.K.</div>
                </div>
              </div>
            </div>
          </div>
          <div className={classes.package}>
            <div className={classes.content}>
              <div className={classes.successHeader}>
                {tx("rapo1223", "I want to study now!")}
              </div>
              <div className={classes.successText}>
                {tx(
                  "rapo13asas1345",
                  "I'm amazed. It's put together very well, in a smart way. It made me want to study."
                )}{" "}
              </div>
              <div className={classes.avatarFlex}>
                <div className={classes.signatureDiv}>
                  <div className={classes.signatureName}>T.A.</div>
                </div>
              </div>
            </div>
          </div>
          <div className={classes.package}>
            <div className={classes.content}>
              <div className={classes.successHeader}>
                {tx("rapo1223as", "You thought of everybody")}
              </div>
              <div className={classes.successText}>
                {tx(
                  "rapo13asas1343",
                  "The app is a real wizzard, it enchants me even if I don't have the strength to study. I received new motivation with it. It's really customizable, in depth and in quantity as well. Fantastically complex, you thought of everything and everybody."
                )}{" "}
              </div>
              <div className={classes.avatarFlex}>
                <div className={classes.signatureDiv}>
                  <div className={classes.signatureName}>D.A.</div>
                </div>
              </div>
            </div>
          </div>
          <div className={classes.package}>
            <div className={classes.content}>
              <div className={classes.successHeader}>
                {tx("rapo1223aswe44", "Easy to use")}
              </div>
              <div className={classes.successText}>
                {tx(
                  "rapo13asas1343zsx44",
                  "I tried WizzWords, and it's fantastic! The app is easy to use, made with very high standards, and it provides effective learning. I really like that you can choose your level of knowledge, so my middle school son can also use it."
                )}{" "}
              </div>
              <div className={classes.avatarFlex}>
                <div className={classes.signatureDiv}>
                  <div className={classes.signatureName}>G.T.</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {!props.alt ? (
          <div style={{ paddingTop: "30px" }}>
            <OrigButton tx={tx} />
          </div>
        ) : (
          ""
        )}
      </div>
    </React.Fragment>
  );
};

export default Successes;
