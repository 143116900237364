import React, { useEffect, useState, Suspense } from "react";

// @material-ui/core components

import {
  makeStyles,
  Theme,
  createStyles,
  ButtonBase,
  Checkbox,
  TextField,
  Snackbar,
} from "@material-ui/core";
import TextForm from "./gift.text";
import { functions } from "../firebaseUtil/firebaseUtil";
import Condition from "utils/Conditional";
import TermsDialog from "./gift.terms";

import Waiter from "../utils/Waiter";
import { phaseIn } from "../utils/screen";
import { useHistory } from "react-router";
import MuiAlert, { AlertProps } from "@material-ui/lab/Alert";
import Legal from "legal/legal";
import firebase from "../firebaseUtil/firebaseUtil";

function Alert(props: AlertProps) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const HuCountries = React.lazy(
  () => import("utils/countries/countries.select.hu")
);
const EnCountries = React.lazy(
  () => import("utils/countries/countries.select.en")
);

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    weaccept: {
      marginTop: "25px",
      marginBottom: "10px",
    },
    cards: { width: "250px" },
    mWrapper: {
      maxWidth: "var(--max-width)",
      marginLeft: "auto",
      marginRight: "auto",
      width: "100%",
      paddingTop: "50px",
      paddingLeft: "20px",
      paddingRight: "20px",
      paddingBottom: "40px",
    },
    error: { border: "1px solid red" },
    fillout: {
      paddingTop: "30px",
      paddingBottom: "15px",
      fontSize: "21px",
      fontFamily: "var(--font-bold)",
      lineHeight: "135%",
    },
    bigtitle: {
      paddingBottom: "20px",
      color: "var(--theme-color)",
      fontSize: "18px",
      fontFamily: "var(--font-bold)",
    },
    text: {
      paddingBottom: "10px",
      fontSize: "18px",
      fontFamily: "var(--font-normal)",
      lineHeight: "135%",
    },
    subHeaderTop: {
      paddingBottom: "20px",
      fontSize: "24px",
      fontFamily: "var(--font-medium)",
    },
    subHeader: {
      paddingTop: "35px",
      paddingBottom: "5px",
      fontSize: "18px",
      fontFamily: "var(--font-medium)",
    },
    oneLine: {
      display: "flex",
      flexDirection: "row",
      width: "100%",
      flexWrap: "wrap",
      justifyContent: "space-between",
      paddingTop: "10px",
    },
    buttonDiv: { marginTop: "40px", textAlign: "right" },
    linkType: {
      fontFamily: "var(--font-medium)",
      color: "var(--theme-color)",
      cursor: "pointer",
    },
    button: {
      background: "var(--theme-color)",
      color: "white",
      fontFamily: "var(--font-medium)",
      fontSize: "18px",
      lineHeight: "125%",
      padding: "14px 22px",
      textTransform: "uppercase",
      borderRadius: "3px",
    },
    disabled: {
      opacity: 0.5,
    },
    frame: {
      background: "#fff4eb",
      padding: "15px 15px 15px 15px",
      borderRadius: "3px",
      border: "1px solid var(--theme-color)",
      marginBottom: "20px",
    },
    flex: {
      display: "flex",
      flexDirection: "row",
      alignItems: "flex-start",
      width: "100%",
      fontSize: "18px",
      fontFamily: "var(--font-medium)",
      lineHeight: "130%",
      marginTop: "8px",
      "@media (max-width:500px)": { flexDirection: "column" },
    },
    noflex: {
      display: "block",
      flexDirection: "row",
      alignItems: "flex-start",
      width: "100%",
      fontSize: "18px",
      fontFamily: "var(--font-medium)",
      lineHeight: "130%",
      marginTop: "8px",
      "@media (max-width:500px)": { flexDirection: "column" },
    },
    flex2: {
      display: "flex",
      flexDirection: "row",
      alignItems: "flex-start",
      width: "100%",
      fontSize: "18px",
      fontFamily: "var(--font-medium)",
      lineHeight: "130%",
      marginTop: "8px",
    },
    flexright: { flex: "0 0 80px", paddingBottom: "10px" },
    flexmiddle: { flex: "1 1 auto", paddingBottom: "10px" },
    flexleft: { flex: "1 1 auto", paddingBottom: "10px" },
    item: { fontFamily: "var(--font-normal)", paddingRight: "15px" },
    itemHeader: { paddingRight: "15px" },
    flexright1: {
      flex: "1 1 auto",
      fontSize: "16px",
      fontFamily: "var(--font-normal)",
      paddingTop: "9px",
    },
    flexleft1: { flex: "0 0 50px" },
    spacer: { height: "10px" },
    copyright: {
      position: "absolute",
      bottom: 15,
      justifySelf: "end",
      flex: "3 1 auto",
      display: "flex",
      alignItems: "flex-end",
      fontFamily: "var(--font-normal)",
      color: "#888888",
      fontSize: "12px",
      margin: "0 auto",
      paddingTop: "15px",
      paddingBottom: "10px",
      lineHeight: "130%",
      background: "white",
    },
    explanation: {
      color: "grey",
      fontFamily: "var(--font-normal",
    },
    backdrop: {
      zIndex: 1800,
      color: "var(--theme-color)",
    },
  })
);

const Checkout = (props: any) => {
  const [backdropOpen, setBackdropOpen] = useState(false);
  const [data, setData] = useState<any>({});
  const [offer, setOffer] = useState({
    price: 0,
    currency: "HUF",
    type: "",
    amount: 0,
  });
  const [acceptTerms, setAcceptTerms] = useState(false);
  const [termsOpen, setTermsOpen] = useState(false);
  const [termsContent, setTermsContent] = useState<any>("");
  const [valid, setValid] = useState(false);
  const [type, setType] = useState("");
  const [submitted, setSubmitted] = useState(false);
  const [alertOpen, setAlertOpen] = useState(false);
  const [emailExists, setEmailExists] = useState(false);

  const classes = useStyles();
  const history = useHistory();

  const { tx } = props;

  useEffect(() => {
    document.getElementById("appPaper")?.scrollTo(0, 0);

    setTimeout(() => {
      phaseIn();
    }, 0);
  }, []);

  useEffect(() => {
    let c = "0";
    switch (props.language) {
      case "hu":
        c = "HU";
        break;
      case "sk":
        c = "SK";
        break;
      case "cs":
        c = "CS";
        break;
      case "ro":
        c = "RO";
        break;
      default:
        c = "HU";
    }
    setData({
      country: c,
      ...JSON.parse(localStorage.getItem("tempis") || "{}"),
    });
  }, [props.language]);

  useEffect(() => {
    if (
      data.firstName &&
      data.lastName &&
      data.country &&
      data.country !== "0" &&
      data.city &&
      data.zip &&
      data.address &&
      data.password?.length > 5 &&
      acceptTerms
    ) {
      setValid(true);

      if (data.country === "HU" && data.company && !data.taxId) {
        setValid(false);
      }
      console.log("valid!");
    } else {
      setValid(false);
      console.log("invalid!");
    }
  }, [data, acceptTerms]);

  // const changeAmount = (e: any) => {
  //   if (parseInt(e.target.value) < 1 || !e.target.value) {
  //     setOffer({ ...offer, amount: 1 });
  //     e.target.value = "1";
  //     return;
  //   }
  //   setOffer({ ...offer, amount: e.target.value });
  // };

  useEffect(() => {
    setOffer({
      price: 11990,
      currency: "HUF",
      type: "own",
      amount: 1,
    });

    setType("own");
  }, [history, props.prices]);

  useEffect(() => {
    setEmailExists(false);
  }, [data.email]);

  const getItem = () => {
    if (type === "own") {
      return (
        <React.Fragment>
          <div className={classes.flex}>
            <div className={classes.flexleft}>
              <div className={classes.itemHeader}>{tx("ioowe", "Item")}</div>
              <div className={classes.item}>
                {tx("montsdhlas13433w5", "WizzWords app for personal use")}
              </div>
            </div>
            <div className={classes.flexmiddle}>
              <div className={classes.itemHeader}>
                {tx("ioweowe", "Amount")}
              </div>
              <div className={classes.item}>
                <TextField
                  type="number"
                  style={{ width: "50px" }}
                  value={offer.amount}
                  inputProps={{ min: 0 }}
                  disabled
                />
              </div>
            </div>
            <div className={classes.flexright}>
              <div className={classes.itemHeader}>{tx("ioowew", "Sum")}</div>
              <div className={classes.item}>
                {props.prices.currencySign?.replace(
                  "xxx",
                  offer.price * offer.amount
                )}
              </div>
            </div>
          </div>
          <div className={classes.noflex}>
            <div className={classes.explanation}>
              {tx(
                "monwewtsdhlwerertawwws2w",
                "After payment you will receive an email with your username and password."
              )}{" "}
              {tx(
                "moasdnwewtsdhlwerertassa",
                "The price includes VAT applicable to your country which will be visible on the invoice we send you."
              )}
            </div>
          </div>
        </React.Fragment>
      );
    }
  };

  const goPay = async () => {
    if (!valid) {
      setSubmitted(true);
      setAlertOpen(true);
      return;
    }

    try {
      await firebase
        .auth()
        .signInWithEmailAndPassword(data.email, "qweorasdflkalsd");
    } catch (e: any) {
      if (e.code !== "auth/user-not-found") {
        setSubmitted(true);
        setAlertOpen(true);
        setEmailExists(true);
        return;
      }
    }
    console.log("new email address");

    setBackdropOpen(true);

    let linkData: any;

    localStorage.setItem("tempis", JSON.stringify(data));

    const paymentLink = functions.httpsCallable("barionPayLinkApp");

    if (data.company) {
      data.taxNumber = data.taxId;
      data.isCompany = true;
      data.companyName = data.company;
      data.companyCity = data.city;
      data.companyCountry = data.country;
      data.companyAddress = data.address;
      data.companyZip = data.zip;
    }

    try {
      linkData = await paymentLink({
        host: document.location.host,
        email: data.email,
        password: data.password,
        price: offer.price,
        currency: props.prices.currency,
        language: (props.language || "hu").replace("en", "hu"),
        pcs: offer.amount,
        ccode: "06ab17c7-b847-4c01-aa58-430cb94e0ac2",
        data: data,
      });
    } catch (e) {
      console.log(e);
    }

    if (linkData.data?.url) {
      window.location.href = linkData.data.url;
    }
    // setTimeout(() => myWindow?.close(), 8000);
    // setBackdropOpen(false);
  };

  const termsChange = (e: any) => {
    setAcceptTerms(e.target.checked);
  };

  const closeDialog = () => {
    setTermsOpen(false);
  };

  const showTerms = () => {
    setTermsContent(<Legal language={props.language} doc="terms" />);
    setTermsOpen(true);
  };

  const showPrivacy = () => {
    setTermsContent(<Legal language={props.language} doc="privacy" />);
    setTermsOpen(true);
  };

  const handleChange = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setData({ ...data, [e.target.id]: e.target.value });
  };

  return (
    <React.Fragment>
      <div className={classes.mWrapper}>
        <div className={classes.subHeaderTop}>
          {tx("basic0asllaasdasdas", "Checkout")}
        </div>
        <div className={classes.text}>
          {tx("basic0asllaasdasdasas", "You buy the following:")}
        </div>
        <div className={classes.frame}>{getItem()}</div>
        <div className={classes.fillout}>
          {tx("aooi899", "Please fill out your details:")}
        </div>
        <div className={classes.oneLine}>
          <TextForm
            submitted={submitted}
            error={!data.firstName}
            data={data}
            label={tx("firsdstNwameasda", "First Name (required)")}
            name={"firstName"}
            handleChange={handleChange}
            required={true}
          />
          <TextForm
            submitted={submitted}
            error={!data.lastName}
            data={data}
            label={tx("lastNsdasdeasda", "Last Name (required)")}
            name={"lastName"}
            handleChange={handleChange}
            required={true}
          />
        </div>
        <div className={classes.oneLine}>
          <TextForm
            submitted={submitted}
            error={!verifyEmail(data.email) || emailExists}
            data={data}
            label={tx("emailNamdsdeasda", "Email (required)")}
            name={"email"}
            handleChange={handleChange}
            more={{ type: "email" }}
            required={true}
            email
            emailExists={emailExists}
          />
          <TextForm
            submitted={submitted}
            error={data.password?.length < 6}
            data={data}
            label={tx("firsdstNwameasdwea", "Password (required)")}
            name={"password"}
            more={{ type: "password" }}
            handleChange={handleChange}
            required={true}
          />
        </div>

        <div className={classes.subHeader}>
          {tx("basdfasic0asllweassub", "Billing Address")}
        </div>
        <div className={classes.oneLine}>
          <TextForm
            data={data}
            label={tx("emailNamdwersdeasda", "Company (optional)")}
            name={"company"}
            handleChange={handleChange}
          />
          {data.country === "HU" && data.company ? (
            <TextForm
              submitted={submitted}
              error={data.country === "HU" && !data.taxId}
              data={data}
              label={tx("esddmawilNamdwersdseasda", "Tax Number (required)")}
              name={"taxId"}
              handleChange={handleChange}
            />
          ) : (
            ""
          )}
        </div>
        <div className={classes.oneLine}>
          <Condition when={props.language === "hu"}>
            <Suspense fallback={<div />}>
              <HuCountries
                submitted={submitted}
                country={data.country}
                handleChange={handleChange}
              />
            </Suspense>
          </Condition>
          <Condition when={props.language === "sk"}>
            <Suspense fallback={<div />}>
              <EnCountries
                submitted={submitted}
                country={data.country}
                handleChange={handleChange}
              />
            </Suspense>
          </Condition>
          <Condition when={props.language === "cs"}>
            <Suspense fallback={<div />}>
              <EnCountries
                submitted={submitted}
                country={data.country}
                handleChange={handleChange}
              />
            </Suspense>
          </Condition>
          <Condition when={props.language === "en"}>
            <Suspense fallback={<div />}>
              <EnCountries
                submitted={submitted}
                country={data.country}
                handleChange={handleChange}
              />
            </Suspense>
          </Condition>
          <Condition when={props.language === "ro"}>
            <Suspense fallback={<div />}>
              <EnCountries
                submitted={submitted}
                country={data.country}
                handleChange={handleChange}
              />
            </Suspense>
          </Condition>

          <TextForm
            submitted={submitted}
            v
            error={!data.zip}
            data={data}
            label={tx("enxtlasdswelastNameasda", "Zip Code (required)")}
            name={"zip"}
            handleChange={handleChange}
            required={true}
          />
        </div>
        <div className={classes.oneLine}>
          <TextForm
            submitted={submitted}
            error={!data.city}
            data={data}
            label={tx("citysubasdftypeklas", "City (required)")}
            name={"city"}
            handleChange={handleChange}
            required={true}
          />
          <TextForm
            submitted={submitted}
            error={!data.address}
            data={data}
            label={tx("enxtaddrelaswelastNameasda", "Address (required)")}
            name={"address"}
            handleChange={handleChange}
            required={true}
          />
        </div>
        <div className={classes.spacer} />
        <div
          className={
            classes.flex2 +
            (!acceptTerms && submitted ? " " + classes.error : "")
          }
        >
          <div className={classes.flexleft1}>
            <Checkbox
              checked={acceptTerms}
              onChange={termsChange}
              inputProps={{ "aria-label": "primary checkbox" }}
            />
          </div>

          <div className={classes.flexright1}>
            {tx(
              "termwwsacc",
              "I accept the Terms and Conditions and the Privacy Policy of NetEnglish Solutions OÜ as laid out here:"
            )}
            <span onClick={showTerms} className={classes.linkType}>
              {" "}
              {tx("term1", "Terms of Usage")}
            </span>
            {" - "}
            <span onClick={showPrivacy} className={classes.linkType}>
              {tx("terms2", "Privacy Policy")}
            </span>
          </div>
        </div>
        <div className={classes.flex2}>
          <div className={classes.flexleft1}></div>

          <div className={classes.flexright1}>
            {tx(
              "termsaccwess",
              "*This service is provided by:*<br/>NetEnglish Solutions OÜ<br/>Hungarian Permanent Establishment.<br/>9700 Szombathely, Hungary<br/>Deák Ferenc utca 29.<br/>Tax number: 27972984-2-18"
            )}
          </div>
        </div>
        <Snackbar
          open={alertOpen}
          autoHideDuration={4000}
          onClose={() => setAlertOpen(false)}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert onClose={() => setAlertOpen(false)} severity="error">
            {tx("lwokerro", "Correct the fields marked in red.")}
          </Alert>
        </Snackbar>

        <div className={classes.buttonDiv}>
          <ButtonBase className={classes.button} onClick={goPay}>
            {tx("ocoasdntinsdujl", "PAYMENT")}
          </ButtonBase>
        </div>

        <TermsDialog
          open={termsOpen}
          closeDialog={closeDialog}
          help={termsContent}
        />
        <Waiter
          text={tx("paypage", "Redirecting to secure payment page.")}
          backdropOpen={backdropOpen}
        />
      </div>
    </React.Fragment>
  );
};

export default Checkout;

export const verifyEmail = (value: any) => {
  var emailRex =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  if (emailRex.test(value)) {
    return true;
  }
  return false;
};
