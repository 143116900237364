import React, { useEffect } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core";
import { ButtonBase } from "@material-ui/core";
import { phaseIn, phaseOut } from "../utils/screen";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    buttonDiv: { marginTop: "20px", textAlign: "right" },

    button: {
      background: "var(--theme-color)",
      color: "white",
      fontFamily: "var(--font-medium)",
      fontSize: "18px",
      lineHeight: "150%",
      padding: "14px 22px",
      textTransform: "uppercase",
      borderRadius: "3px",
    },
    bigtitle: {
      paddingBottom: "20px",
      color: "var(--theme-color)",
      fontSize: "21px",
      fontFamily: "var(--font-bold)",
    },
    text: {
      paddingBottom: "10px",
      fontSize: "18px",
      fontFamily: "var(--font-normal)",
      lineHeight: "150%",
      flex: "1 1 auto",
    },
    header: {
      fontSize: "18px",
      fontFamily: "var(--font-bold)",
      lineHeight: "150%",
      textAlign: "center",
    },
    subheader: {
      paddingBottom: "15px",
      fontSize: "16px",
      fontFamily: "var(--font-medium)",
      lineHeight: "150%",
      textAlign: "center",
    },
    cover: {
      maxWidth: "700px",
      marginLeft: "auto",
      marginRight: "auto",
      padding: "20px",
      marginTop: "30px",
    },
    coupon: {
      paddingBottom: "5px",
      fontSize: "18px",
      fontFamily: "var(--font-normal)",
      lineHeight: "150%",
      textAlign: "center",
    },
  })
);

const GiftSuccess = (props: any) => {
  const classes = useStyles();
  const { tx } = props;

  useEffect(() => {
    setTimeout(() => {
      phaseIn();
    }, 1000);
  }, []);

  const goContinue = () => {
    phaseOut();
    setTimeout(() => {
      document.location.href =
        "https://app.wizzwords.net/?lang=" + props.language;
    }, 150);
  };

  return (
    <React.Fragment>
      <div className={classes.cover}>
        <div className={classes.bigtitle}>
          {tx("succsdllwlwe", "Successful Transaction!")}
        </div>
        <div className={classes.text}>
          <p>
            {tx(
              "subsduowowow2a",
              "You successfully bought your lincence for WizzWords!"
            )}{" "}
          </p>
          <p>
            {tx(
              "subsduowowow1a",
              "You will receive an email with your password."
            )}{" "}
            {tx(
              "subsduowowow2",
              "Please check your spam folder as well if you wouldn't receive it."
            )}{" "}
          </p>
          <p>
            {tx(
              "subsduowowow4",
              "If you still have troubles please see our Frequently Asked Questions page [[[here]]].",
              "",
              "/" + props.language + "/support"
            )}
          </p>
        </div>

        <div className={classes.buttonDiv}>
          <ButtonBase className={classes.button} onClick={goContinue}>
            {tx("ocoasdntinsdujslwe", "Start the app!")}
          </ButtonBase>
        </div>
      </div>
    </React.Fragment>
  );
};

export default GiftSuccess;
