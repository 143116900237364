import React, { useEffect, useState, useCallback } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core";
import { firestore } from "../firebaseUtil/firebaseUtil";
import Terms from "./legal.terms";
import Privacy from "./legal.privacy";
import { prepareFormat } from "../utils/globalize";
import { decodedStr } from "../routing";
import Subscription from "./legal.subscription";
import Cookies from "./legal.cookies";
import Impressum from "./legal.impresszum";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    text: {},
    opaque: { opacity: 0, transition: "all 0.3s" },
    nonopaque: { opacity: 1, transition: "all 0.3s" },
  })
);

const Legal = (props: any) => {
  const [termsTranslation, setTermsTranslation] = useState<any>({});
  const [visible, setVisible] = useState(false);

  const classes = useStyles();

  useEffect(() => {
    const loadTermsTranslation = async () => {
      const transColl = firestore
        .collection("translations")
        .where("type", "==", "webpages")
        .where("purpose", "==", "legal")
        .where("language", "==", props.language);

      let termTrans: any = [];

      try {
        termTrans = await transColl.get();
        setTermsTranslation({
          ...termTrans.docs[0].data().translations,
          ...termTrans.docs[1].data().translations,
          ...termTrans.docs[2].data().translations,
        });
        setTimeout(() => setVisible(true), 0);
      } catch (e) {
        console.log(e);
        setTimeout(() => setVisible(true), 0);
        return {};
      }
    };

    if (props.language) {
      loadTermsTranslation();
    }
  }, [props.language]);

  const tt = useCallback(
    (uuid: string, orig: string = "", avar: string = "", bvar: string = "") => {
      orig = termsTranslation[uuid || "bibibi"] || orig;
      orig = orig.replace(/xxx/g, avar);
      orig = orig.replace(/yyy/g, bvar);
      return prepareFormat(decodedStr(orig));
    },
    [termsTranslation]
  );

  const getText = () => {
    switch (props.doc) {
      case "terms":
        return <Terms tt={tt} />;
      case "privacy":
        return <Privacy tt={tt} />;
      case "subscription":
        return <Subscription tt={tt} />;
      case "cookies":
        return <Cookies tt={tt} />;
      case "impress":
        return <Impressum tt={tt} />;
    }
  };

  return (
    <React.Fragment>
      <div className={visible ? classes.nonopaque : classes.opaque}>
        {getText()}
      </div>
    </React.Fragment>
  );
};

export default Legal;
