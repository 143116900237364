import React, { useState, useRef, useEffect } from "react";
import MenuIcon from "@material-ui/icons/Menu";
import { makeStyles, Theme, createStyles, IconButton } from "@material-ui/core";
import DrawerMine from "./header.drawer";
import { useLocation, useHistory } from "react-router-dom";
import { phaseOut } from "../utils/screen";
import LanguageList from "./header.language";
import LanguageMenu from "./header.languagemenu";
// import LanguageMenu from './header.languagemenu';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    menuWrapper: {
      position: "absolute",
      top: "3px",
      right: "3px",
      filter: "blur(15px)",
      "&&.bla": {
        filter: "blur(0px)",
        transition: "all 1s",
      },
    },
    menuFlex: {
      marginTop: "15px",
      marginRight: "25px",
      display: "none",
      fontFamily: "var(--font-medium)",
      fontSize: "18px",
      alignItems: "center",
      "@media (min-width: 1000px)": { display: "flex" },
    },
    menuFlexSmall: {
      display: "block",
      "@media (min-width: 1000px)": { display: "none" },
    },
    menuItem: {
      cursor: "pointer",
      paddingLeft: "35px",
    },
    activeMenuItem: {
      cursor: "pointer",
      paddingLeft: "35px",
      color: "var(--theme-color)",
      fontFamily: "var(--font-bold)",
    },
    flagButton: { paddingTop: "10px" },
    img: { height: "20px" },
  })
);

const AppMenu = (props: any) => {
  const classes = useStyles();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [open, setOpen] = useState(false);
  const [resell, setResell] = useState(false);
  const location = useLocation();
  const history = useHistory();
  const menu = useRef<HTMLButtonElement>(null);

  const openMenu = () => {
    setOpen(!open);
  };

  const { tx } = props;

  const toggleMenu = () => {
    setDrawerOpen(!drawerOpen);
  };

  useEffect(() => {
    const b = localStorage.getItem("reseller");

    if (b) {
      setResell(true);
    }
  }, [location.pathname]);

  const getActive = () => {
    if (location.pathname.indexOf("/pricing") > -1) {
      return 1;
    }

    if (location.pathname.indexOf("/moreinfo") > -1) {
      return 2;
    }

    if (location.pathname.indexOf("/support") > -1) {
      return 3;
    }

    if (location.pathname.indexOf("/gift") > -1) {
      return 4;
    }

    if (location.pathname.indexOf("/reseller") > -1) {
      return 5;
    }

    return 0;
  };

  const sel = getActive();

  const goTo = (url: string) => {
    if (url === location.pathname) {
      return;
    }
    phaseOut();
    setTimeout(() => history.push(url), 150);
  };

  return (
    <React.Fragment>
      <div className={classes.menuWrapper + props.bla}>
        <div className={classes.menuFlex}>
          <div
            className={sel === 0 ? classes.activeMenuItem : classes.menuItem}
            onClick={() => goTo("/" + props.language)}
          >
            {tx("ghpo2", "Home")}
          </div>
          {/* <div
            className={sel === 1 ? classes.activeMenuItem : classes.menuItem}
            onClick={() => goTo("/pricing")}
          >
            {tx("ghpo3", "Pricing")}
          </div>
          <div
            className={sel === 4 ? classes.activeMenuItem : classes.menuItem}
            onClick={() => goTo("/gift")}
          >
            {tx("ghpo4", "As a gift")}
          </div> */}
          {/* <div
            className={sel === 2 ? classes.activeMenuItem : classes.menuItem}
            onClick={() => goTo("/moreinfo")}
          >
            {tx("ghpo5", "More info")}
          </div> */}
          {resell ? (
            <div
              className={sel === 5 ? classes.activeMenuItem : classes.menuItem}
              onClick={() => goTo("/" + props.language + "/reseller")}
            >
              {tx("ghpo6csd", "Resell")}
            </div>
          ) : null}
          <div
            className={sel === 3 ? classes.activeMenuItem : classes.menuItem}
            onClick={() => goTo("/" + props.language + "/support")}
          >
            {tx("ghpo6", "Support")}
          </div>

          <LanguageList
            language={props.language}
            setLanguage={props.setLanguage}
          />
        </div>

        <div className={classes.menuFlexSmall}>
          <IconButton
            className={classes.flagButton}
            onClick={openMenu}
            ref={menu}
          >
            <img
              alt={"flag"}
              src={`/flags/${props.language}.png`}
              height="15px"
              className={classes.img}
            />
          </IconButton>
          <IconButton onClick={toggleMenu}>
            <MenuIcon />
          </IconButton>
          <LanguageMenu
            open={open}
            setOpen={setOpen}
            anchor={menu.current}
            setLanguage={props.setLanguage}
          />
        </div>
      </div>
      <DrawerMine
        drawerOpen={drawerOpen}
        setDrawerOpen={setDrawerOpen}
        tx={tx}
        language={props.language}
        setLanguage={props.setLanguage}
      />
    </React.Fragment>
  );
};

export default AppMenu;
