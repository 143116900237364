import React, { useState, useRef, useCallback, useEffect } from "react";
import { Switch, Route, useLocation, useHistory } from "react-router-dom";
import {
  makeStyles,
  Theme,
  createStyles,
  CircularProgress,
} from "@material-ui/core";
import HomePage from "./homepage/homepage";
import AppHeader from "./header/header";
import Footer from "./footer/footer";
// import Pricing from "./pricing/pricing";
import Gift from "./gift/gift";
import MoreInfo from "./moreinfo/moreinfo";
import Faq from "./faq/faq";
import { firestore } from "./firebaseUtil/firebaseUtil";
import { prepareFormat } from "./utils/globalize";
import Checkout from "./gift/gift.checkout";
import GiftFailure from "./gift/gift.failure";
import GiftPending from "./gift/gift.pending";
import GiftSuccess from "./gift/gift.success";
import GiftWrong from "./gift/gift.wrong";
import Legal from "legal/legal";
import { supportEmail } from "./utils/languages";
import ReactGA from "react-ga";
import ReactPixel from "react-facebook-pixel";
import GiftSuccessSignup from "./gift/gift.successsignup";
// import AltHome from "./althome/althome";
import Reseller from "reseller/reseller";

ReactGA.initialize("UA-163818110-2", { debug: false });
ReactGA.plugin.require("ec"); // not 'ecommerce'
ReactGA.plugin.require("ecommerce"); // not 'ecommerce'

// const advancedMatching = {}; // optional, more info: https://developers.facebook.com/docs/facebook-pixel/advanced/advanced-matching
const options = {
  autoConfig: true, // set pixel's autoConfig
  debug: false, // enable logs
};
ReactPixel.init("376330536685036", undefined, options);

export const doSale = (sale: any) => {
  // console.log("sale");

  ReactPixel.track("Purchase", {
    content: [{ id: sale.SKU, quantity: sale.qty }],
    value: sale.value,
    currency: sale.currency,
  });

  ReactGA.plugin.execute("ecommerce", "addTransaction", {
    id: sale.SKU,
    name: sale.name,
    revenue: sale.value,
    currency: sale.currency,
  });

  ReactGA.plugin.execute("ecommerce", "send", {});
  ReactGA.plugin.execute("ecommerce", "clear", {});
};

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appPaper: {
      overflowX: "hidden",
      overflowY: "auto",
      height: "var(--vh)",
      paddingTop: "50px",
      position: "relative",
      transition: "opacity 0.15s, z-index: 0s 0.15s",
      filter: "blur(15px)",
      "&&.bla": {
        filter: "blur(0px)",
        transition: "all 1s",
      },
    },
    fab: {
      position: "absolute",
      bottom: 30,
      right: 20,
      background: "var(--theme-color)",
      color: "white",
      borderRadius: "3px",
      opacity: 0,
      transition: "all 0.3s",
      fontFamily: "var(--font-medium)",
      "@media (hover: none)": {
        background: "var(--theme-color)",
      },
      "&&:hover": { background: "var(--theme-color)" },
    },
    fabActive: { opacity: 1 },
    flow: {
      position: "relative",
      minHeight: "calc(100% - 50px)",
      paddingBottom: "185px",
    },
    flowIOS: {
      position: "relative",
      minHeight: "100%",
      paddingBottom: "229px",
    },
    cover: {
      position: "fixed",
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      zIndex: 1500,
      background: "white",
      "&&.bla": {
        display: "none",
        opacity: 0,
        transition: "opacity 0.3s, display 0s 0.3s",
      },
    },
    circular: {
      transform: "translate(-50%,-50%)",
      position: "absolute",
      top: "50%",
      left: "50%",
      color: "var(--theme-color)",
    },
    circle: {
      stroke: "var(--theme-color)",
    },
  })
);

// const { detect } = require("detect-browser");
// const browser = detect();
const isIOS = false;
// browser.os === "iOS";

const RoutingUI = (props: any) => {
  const classes = useStyles();
  const [trigger, setTrigger] = useState(false);
  // const [fabActive, setFabActive] = useState(false);
  const [language, setLanguage] = useState("");
  const [bla, setBla] = useState("");
  const [appTranslation, setAppTranslation] = useState<any>({});
  const buttonRef = useRef<HTMLElement>(null);
  const [prices, setPrices] = useState<any>({
    own: 11990,
    gift: 11990,
    currency: "HUF",
    currencySign: "xxx Ft",
    type: "own",
    origPrices: {},
  });
  const signUp = () => {
    setTimeout(() => {
      window.open(
        "https://app.wizzwords.net/login/signup?lang=" + language,
        "_blank"
      );
    }, 150);
  };

  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    // ReactPixel.pageView();
    // ReactGA.pageview(location.pathname + location.search);
  }, [location.pathname, location.search]);

  useEffect(() => {}, [location, language, history]);

  console.log("thisaaaa" + language);

  useEffect(() => {
    const loadSiteTranslation = async (ccode: any) => {
      console.log("ccode", ccode, language);

      if (["hu", "it", "es", "pl"].includes(ccode)) {
        if (language !== ccode) {
          setLanguage(ccode);
          console.log("this" + ccode);
          localStorage.setItem("language", ccode);
          return;
        }
      }

      console.log(1 + ccode);

      if (ccode.length === 5 && parseInt(ccode) > 35000) {
        if (ccode !== localStorage.getItem("ref")) {
          let gaccount = await firestore
            .doc("/companies/" + ccode + "/data/customize")
            .get();
          if (gaccount.exists) {
            setLanguage(gaccount.data()?.language || "it");
            localStorage.setItem("language", gaccount.data()?.language || "it");
            localStorage.setItem("ref", ccode);
            history.push("/" + localStorage.getItem("language") || "it");
            return;
          }
        } else {
          setLanguage(localStorage.getItem("language") || "it");
          localStorage.setItem(
            "language",
            localStorage.getItem("language") || "it"
          );
          history.push("/" + localStorage.getItem("language") || "it");
          return;
        }
      }

      console.log(language);

      if (!language) {
        const tempLang = localStorage.getItem("language");
        setLanguage(tempLang || "it");

        localStorage.setItem("language", tempLang || "it");
        history.push("/" + localStorage.getItem("language") || "it");

        return;
      }

      console.log(firestore.settings);

      const transColl = firestore
        .collection("translations")
        .where("type", "==", "webpages")
        .where("lesson", "==", "home")
        .where("language", "==", language || "it");

      const newIdea = await firestore.doc("/version/version").get();

      console.log(newIdea.data());

      console.log("mire");
      console.log(language);

      let appTrans: any = [];

      try {
        console.log("more2");
        appTrans = await transColl.get();
        console.log("more3");
        console.log(appTrans.docs.length);

        setAppTranslation(appTrans.docs[0].data().translations);

        setBla(" bla");
      } catch (e) {
        console.log(e);
        return {};
      }
    };

    const ccode = location.pathname.split("/")[1]?.trim();

    loadSiteTranslation(ccode);
  }, [language, location, history]);

  // useEffect(() => {
  //   const loadPrices = async () => {
  //     let newPrices: any;

  //     try {
  //       newPrices = (
  //         await firestore.doc(`/pricing/${language || "hu"}`).get()
  //       ).data() as any;

  //       console.log(newPrices);
  //       setPrices({
  //         ...newPrices,
  //       });
  //     } catch (e) {
  //       console.log("prices not loaded", e);
  //     }
  //   };

  //   // loadPrices();
  // }, [language]);

  const tx = useCallback(
    (uuid: string, orig: string = "", avar: string = "", bvar: string = "") => {
      orig = appTranslation[uuid || "bibibi"] || orig;
      orig = orig.replace(/xxx/g, avar);
      if (orig.includes("[[[")) {
        orig = orig.replace(
          /\[\[\[(.*?)\]\]\]/,
          "<a target='_blank' href=" + bvar + ">$1</a>"
        );
      }

      return prepareFormat(decodedStr(orig));
    },
    [appTranslation]
  );

  const scrollCheck = (e: any) => {
    if (e.target.id !== "appPaper") {
      e.preventDefault();
      return;
    }
    if (e.target.scrollTop > 0 && !trigger) {
      setTrigger(true);
    }

    if (e.target.scrollTop === 0 && trigger) {
      setTrigger(false);
    }

    // if (
    //   (buttonRef.current?.getBoundingClientRect().top || 0) < 0 &&
    //   !fabActive
    // ) {
    //   setFabActive(true);
    // }

    // if (
    //   (buttonRef.current?.getBoundingClientRect().top || 0) >= 0 &&
    //   fabActive
    // ) {
    //   setFabActive(false);
    // }
  };

  return (
    <React.Fragment>
      <Switch>
        <Route path="/frame/terms">
          <Legal language={language} doc="terms" />
        </Route>
        <Route path="/">
          <div className={classes.cover + " bla"} id="cover">
            <div className={classes.circular}>
              <CircularProgress classes={{ circle: classes.circle }} />
            </div>
          </div>
          <AppHeader
            trigger={trigger}
            tx={tx}
            language={language}
            setLanguage={setLanguage}
            bla={bla}
          />
          <div
            className={classes.appPaper + bla}
            id="appPaper"
            onScroll={scrollCheck}
          >
            <div className={isIOS ? classes.flowIOS : classes.flow}>
              <Switch>
                <Route path="/legal/terms">
                  <Legal language={language} doc="terms" />
                </Route>
                {/* <Route path="/pricing">
                  <Pricing
                    tx={tx}
                    prices={prices}
                    setPrices={setPrices}
                    language={language}
                  />
                </Route> */}
                <Route path="/gift">
                  <Gift
                    tx={tx}
                    prices={prices}
                    setPrices={setPrices}
                    language={language}
                  />
                </Route>
                <Route path="/nopayment/:lang">
                  <GiftFailure tx={tx} />
                </Route>
                <Route path="/:lang/pending">
                  <GiftPending tx={tx} />
                </Route>
                <Route path="/failed/:lang">
                  <GiftWrong tx={tx} />
                </Route>
                <Route path="/:lang/success">
                  <GiftSuccess tx={tx} language={language} />
                </Route>
                <Route path="/successapp/:lang">
                  <GiftSuccessSignup tx={tx} />
                </Route>
                <Route path="/checkout/:lang">
                  <Checkout
                    tx={tx}
                    prices={prices}
                    setPrices={setPrices}
                    language={language}
                  />
                </Route>
                <Route path="/:lang/support">
                  <Faq
                    tx={tx}
                    support={supportEmail(language)}
                    language={language}
                  />
                </Route>
                <Route path="/moreinfo/:lang">
                  <MoreInfo buttonRef={buttonRef} tx={tx} language={language} />
                </Route>

                {/* <Route path="/:lang/web1">
                  <AltHome
                    tx={tx}
                    language={language}
                    setLanguage={setLanguage}
                  />
                </Route> */}
                <Route path="/:lang/reseller">
                  <Reseller
                    tx={tx}
                    language={language}
                    setLanguage={setLanguage}
                  />
                </Route>
                <Route path="/:lang">
                  <HomePage
                    tx={tx}
                    language={language}
                    setLanguage={setLanguage}
                  />
                </Route>
              </Switch>
              <Footer tx={tx} language={language} isIOS={isIOS} />
            </div>
          </div>

          {/* <Fab
            onClick={signUp}
            variant="extended"
            className={classes.fab + (fabActive ? " " + classes.fabActive : "")}
          >
            {tx("triyioasdsaa", "Akciós ajánlat!")}
          </Fab> */}
        </Route>
      </Switch>
    </React.Fragment>
  );
};

export default RoutingUI;

export const encodedStr = (rawStr: string) => {
  return rawStr
    .replace(/&/g, "&amp;")
    .replace(/>/g, "gt;")
    .replace(/</g, "lt;");
};

export const decodedStr = (rawStr: string) => {
  return rawStr
    .replace(/&amp;/g, "&")
    .replace(/&gt;/g, ">")
    .replace(/&lt;/g, "<")
    .replace(/&nbsp;/g, " ");
};
