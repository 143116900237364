import React, { useEffect } from "react";
import { BrowserRouter as Router } from "react-router-dom";
// import aos from "aos";
// import "aos/dist/aos.css";
import "./App.css";
import RoutingUI from "./routing";

function setDocHeight() {
  document.documentElement.style.setProperty("--vh", `${window.innerHeight}px`);
  document.documentElement.style.setProperty("--vw", `${window.innerWidth}px`);

  if (window.innerHeight > 600) {
    document.documentElement.style.setProperty("--logoHeight", `170px`);
    document.documentElement.style.setProperty("--logoPadding", `25px`);
  } else {
    document.documentElement.style.setProperty("--logoHeight", `135px`);
    document.documentElement.style.setProperty("--logoPadding", `0px`);
  }
}

function App() {
  useEffect(() => {
    window.addEventListener("resize", setDocHeight);
    window.addEventListener("orientationchange", setDocHeight);
    setDocHeight();
  }, []);

  return (
    <Router>
      <RoutingUI />
    </Router>
  );
}

export default App;
