import React from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    hero: {
      maxWidth: "var(--max-width)",
      marginLeft: "auto",
      marginRight: "auto",
      display: "flex",
      flexDirection: "row",
      width: "100%",
      flexWrap: "wrap",
      paddingTop: "20px",
      paddingLeft: "20px",
      paddingRight: "20px",
    },
    heroReverse: {
      maxWidth: "var(--max-width)",
      marginLeft: "auto",
      marginRight: "auto",
      display: "flex",
      flexDirection: "row-reverse",
      width: "100%",
      flexWrap: "wrap",
      paddingTop: "50px",
      paddingLeft: "20px",
      paddingRight: "20px",
    },
    heroLeft: {
      flex: "1 1 100%",
      padding: "10px",
      paddingTop: "40px",
      "@media (min-width: 750px)": {
        flex: "1 1 50%",
      },
    },
    heroRight: {
      flex: "1 1 100%",
      "@media (min-width: 750px)": {
        flex: "1 1 50%",
      },
      textAlign: "center",
      position: "relative",
      minHeight: "350px",
    },
    whyHeader: {
      fontFamily: "var(--font-header)",
      fontWeight: "bold",
      fontSize: "36px",
      lineHeight: "150%",
      marginBottom: "40px",
      color: "var(--theme-color)",
    },
    fore: {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%,-50%) scale(0.5)",
      transformOrigin: "50% 50%",
      height: "180%",
    },
    patch: {
      position: "absolute",
      transform: "translate(-40%,-50%)",
      top: "50%",
      left: "50%",
    },
    patchReverse: {
      position: "absolute",
      transform: "translate(-50%,-50%)",
      top: "50%",
      left: "50%",
    },
    smallheader: {
      fontFamily: "var(--font-medium)",
      fontSize: "18px",
      lineHeight: "150%",
    },
    smalltext: {
      fontFamily: "var(--font-normal)",
      fontSize: "18px",
      lineHeight: "150%",
      color: "#898989",
    },
    summary: {
      background: "rgba(224,102,89,0.05)",
      width: "100%",
      minHeight: "200px",
      paddingBottom: "25px",
    },
    founder: {
      fontFamily: "var(--font-normal)",
      fontSize: "22px",
      marginTop: "40px",
      marginBottom: "20px",
    },
    bubble: {
      background: "white",
      border: "1px solid #dddddd",
      padding: "25px",
      borderRadius: "10px",
      fontFamily: "var(--font-normal)",
      fontSize: "18px",
      color: "black",
      lineHeight: "150%",
      marginBottom: "25px",
    },
    bubblePara: {
      marginTop: "10px",
    },
  })
);

const Experience = (props: any) => {
  const classes = useStyles();
  const { tx } = props;

  return (
    <React.Fragment>
      <div className={classes.summary}>
        <div className={props.reverse ? classes.heroReverse : classes.hero}>
          <div className={classes.heroLeft}>
            <div className={classes.whyHeader}>
              {tx(
                "yapo2asdfa",
                "Here is the end of forgetting words you once learned"
              )}
            </div>

            <div className={classes.bubble}>
              <div className={classes.bubblePara}>
                {tx(
                  "yapo7a1a",
                  "Did you know that there's a scientific method to remember something you learn?"
                )}
              </div>
              <div className={classes.bubblePara}>
                {tx(
                  "yapo8a2a",
                  "Typically by Sunday night you will only remember a fraction of the words you learned on Monday. Has this ever happened to you?"
                )}
              </div>
              <div className={classes.bubblePara}>
                {tx(
                  "yapo9a3a",
                  "The good news is that with this new scientific method called “spaced repetition” you will be able to remember words forever."
                )}{" "}
                {tx("yapo10a4a", "WizzWords is built on this method.")}
              </div>
              <div className={classes.bubblePara}>
                {tx(
                  "yapo11a5a",
                  "The real magic is that *this application knows exactly how often you have to review a word you learned earlier,* so it “burns it” into your memory forever."
                )}
              </div>
              <div className={classes.bubblePara}>
                {tx(
                  "yapo12a6a",
                  "The other reason people can’t remember words is that they’re trying to memorize it out of context."
                )}{" "}
                {tx(
                  "yapo13a12a",
                  "The solution to this is to learn words while reading (or listening to) a text, a story - exactly how you will learn them with WizzWords."
                )}
              </div>
            </div>
          </div>
          <div className={classes.heroRight}>
            <img alt="phone" className={classes.fore} src="/teacher.png" />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Experience;
