import React, { useEffect } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      marginTop: "0px",
      padding: "25px",
      paddingTop: "0px",
      maxWidth: "var(--max-width)",
      marginLeft: "auto",
      marginRight: "auto",
      marginBottom: "45px",
    },
    text: {
      fontSize: "18px",
      fontFamily: "var(--font-normal)",
      marginBottom: "10px",
      lineHeight: "130%",
    },
    mainHeader: {
      fontSize: "26px",
      fontFamily: "var(--font-header)",
      fontWeight: "bold",
      marginTop: "25px",
      marginBottom: "15px",
      lineHeight: 1.3,
    },
    header: {
      fontSize: "24px",
      fontFamily: "var(--font-bold)",
      marginTop: "25px",
      marginBottom: "15px",
      lineHeight: 1.3,
    },
  })
);

const Cookies = (props: any) => {
  const classes = useStyles();

  useEffect(() => {
    const script = document.createElement("script");

    script.src =
      "https://consent.cookiebot.com/e6de70b5-a7da-4e15-a583-3948b1554306/cd.js";
    script.async = true;

    document.getElementById("cookiewrap")?.appendChild(script);
  }, []);

  return (
    <React.Fragment>
      <div id="cookiewrap" className={classes.wrapper}></div>
    </React.Fragment>
  );
};

export default Cookies;
