import React from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { verifyEmail } from "gift/gift.checkout";
import firebase from "../firebaseUtil/firebaseUtil";
import AlertDialog from "./homepage.emailexists";

export default function BuyDialog(props: any) {
  const [alertOpen, setAlertOpen] = React.useState(false);
  const [valid, setValid] = React.useState(false);

  const handleClose = () => {
    props.setOpen(false);
  };

  const doPay = () => {
    firebase
      .auth()
      .signInWithEmailAndPassword(props.email, "qweorasdflkalsd")
      .then((item) => {
        setAlertOpen(true);
      })
      .catch((e: any) => {
        console.log(e);
        if (e.code === "auth/user-not-found") {
          props.setOpen(false);
          props.buyThis();
        }
        if (e.code === "auth/wrong-password") {
          setAlertOpen(true);
          setValid(false);
        }
      });
  };
  const changeEmail = (e: any) => {
    props.setEmail(e.target.value);
    setValid(verifyEmail(e.target.value));
  };

  return (
    <React.Fragment>
      <Dialog
        open={props.open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          {props.tx("Acquista app...", "Acquista app...")}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {props.tx(
              "xxforckowe",
              "Digita il tuo indirizzo email. (Questo sarà il tuo nome utente.)"
            )}
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label={props.tx("emailadd", "Email Address")}
            type="email"
            fullWidth
            value={props.email}
            onChange={changeEmail}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            {props.tx("cancel", "Cancel")}
          </Button>
          <Button onClick={doPay} color="primary" disabled={!valid}>
            {props.tx("paymento", "Payment")}
          </Button>
        </DialogActions>
      </Dialog>
      <AlertDialog open={alertOpen} setOpen={setAlertOpen} />
    </React.Fragment>
  );
}
