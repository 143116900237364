import React from "react";
import Dialog from "@material-ui/core/Dialog";
import { DialogContent, DialogActions, Button } from "@material-ui/core";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { tx } from "../utils/globalize";

const styles = (theme: Theme) => ({
  content: { padding: "8px" },
  paper: {
    margin: "10px",
  },
  intro: {
    fontSize: "18px",
    fontFamily: "var(--font-normal)",
    lineHeight: "150%",
  },
  actions: {
    paddingTop: "0px",
    paddingBottom: "0px",
  },
});
const useStyles = makeStyles(styles as any);

const TermsDialog = (props: any) => {
  const classes = useStyles();

  return (
    <Dialog
      open={props.open}
      classes={{ paper: classes.paper }}
      onClose={props.closeDialog}
      scroll={"paper"}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
    >
      <DialogContent dividers={true} classes={{ root: classes.content }}>
        <div className={classes.intro}>{props.help}</div>
      </DialogContent>
      <DialogActions className={classes.actions}>
        <Button onClick={props.closeDialog} color="primary">
          {tx("closesllssls", "Close")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default TermsDialog;
