import React, { useEffect } from "react";

// @material-ui/core components

import { makeStyles, Theme, createStyles, ButtonBase } from "@material-ui/core";
import { useHistory } from "react-router";
import CheckIcon from "@material-ui/icons/Check";
import { phaseIn, phaseOut } from "../utils/screen";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    hero: {
      maxWidth: "var(--max-width)",
      marginLeft: "auto",
      marginRight: "auto",
      width: "100%",
      paddingBottom: "30px",
      paddingTop: "80px",
      paddingLeft: "20px",
      paddingRight: "20px",
    },
    morelink: {
      marginTop: "5px",
      color: "grey",
      fontFamily: "var(--font-normal)",
      fontSize: "16px",
      cursor: "pointer",
    },
    morelink1: {
      marginTop: "25px",
      color: "grey",
      fontFamily: "var(--font-normal)",
      fontSize: "18px",
      cursor: "pointer",
      textAlign: "center",
    },
    bigtitle: {
      fontFamily: "Kalam, cursive",
      fontSize: "36px",
      lineHeight: 1.5,
      marginBottom: "40px",
      textAlign: "center",
    },
    coupon: {
      marginTop: "10px",
      marginBottom: "10px",
      textAlign: "center",
    },
    smalltitle: {
      paddingTop: "15px",
      paddingBottom: "20px",
      color: "black",
      fontSize: "18px",
      fontFamily: "var(--font-bold)",
    },
    flex: {
      display: "flex",
      flexDirection: "row",
      width: "100%",
      fontSize: "18px",
      fontFamily: "var(--font-normal)",
      marginBottom: "11px",
      lineHeight: "150%",
    },
    flexleft: { flex: "0 0 auto", paddingRight: "9px" },
    flexright: { flex: "1 1 auto" },
    text: {
      paddingBottom: "10px",
      fontSize: "18px",
      fontFamily: "var(--font-bold)",
      lineHeight: "150%",
      flex: "1 1 auto",
      textAlign: "center",
      paddingTop: "25px",
    },
    textsimple: {
      paddingBottom: "20px",
      fontSize: "18px",
      fontFamily: "var(--font-normal)",
      lineHeight: "150%",
    },
    packageWrapper: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-around",
      flexWrap: "wrap",
      alignItems: "center",

      "@media (min-width: 660px)": {
        display: "block",
        marginLeft: "auto",
        marginRight: "auto",
      },
      "@media (min-width: 800px)": {
        display: "flex",
        marginLeft: "auto",
        marginRight: "auto",
      },
    },
    package: {
      flex: "0 0 290px",
      marginLeft: "10px",
      marginRight: "10px",
      marginTop: "20px",
      marginBottom: "10px",
      backgroundImage: 'url("/greypatch.png")',
      backgroundSize: "100% 100%",
      minHeight: "180px",
      width: "290px",
      borderRadius: "5px",
      "@media (min-width: 660px)": {
        marginLeft: "auto",
        marginRight: "auto",
      },
      "@media (min-width: 800px)": {
        width: "260px",
        flex: "0 0 260px",
        marginLeft: "auto",
        marginRight: "auto",
      },
      "&&.bla": {
        backgroundImage: 'url("/colorpatch.png")',
      },
    },
    intWrapper: {
      display: "flex",
      minHeight: "200px",
      flexDirection: "column",
      justifyContent: "space-around",
      flexWrap: "wrap",
      alignItems: "center",
      margin: "10px auto 25px auto",
      paddingTop: "10px",
    },
    recommended: { minHeight: "250px" },
    length: {
      fontFamily: "var(--font-medium)",
      fontSize: "18px",
      padding: "5px 10px",
      border: "1px solid lightgrey",
      borderRadius: "3px",
      maxWidth: "200px",
      textAlign: "center",
      color: "grey",
    },
    discount: {
      fontFamily: "var(--font-bold)",
      fontSize: "18px",
      textAlign: "center",
    },
    crossout: {
      fontFamily: "var(--font-bold)",
      fontSize: "18px",
      color: "#ED4831",
      textDecoration: "line-through",
    },
    yousave: {
      paddinTop: "6px",
      fontFamily: "var(--font-medium)",
      color: "grey",
    },
    price: {
      fontFamily: "var(--font-normal)",
      fontSize: "45px",
      color: "var(--theme-color)",
    },
    line: {
      height: "0px",
      width: "100%",
      borderBottom: "1px solid var(--line-grey)",
      marginBottom: "18px",
    },

    button: {
      background: "var(--theme-color)",
      color: "white",
      fontFamily: "var(--font-medium)",
      fontSize: "18px",
      lineHeight: "150%",
      padding: "14px 22px",
      textTransform: "uppercase",
      borderRadius: "3px",
      marginLeft: "10px",
    },
    linkWrapper: {
      textAlign: "right",
    },
    linkDiv: {
      display: "inline-block",
      flexDirection: "row",
      alignItems: "center",
      textAlign: "right",
      color: "var(--theme-color)",
      fontSize: "18px",
      fontFamily: "var(--font-bold)",
      cursor: "pointer",
    },
    copyright: {
      justifySelf: "end",
      flex: "3 1 auto",
      display: "flex",
      alignItems: "flex-end",
      fontFamily: "var(--font-normal)",
      color: "#888888",
      fontSize: "12px",
      margin: "0 auto",
      paddingTop: "15px",
      paddingBottom: "10px",
      lineHeight: "150%",
      background: "white",
      position: "absolute",
      bottom: "15px",
    },
    expired: {
      background: "var(--theme-color-bg)",
      padding: "5px 15px 5px 15px",
      borderRadius: "3px",
      border: "1px solid var(--theme-color)",
      marginBottom: "20px",
    },
    status: {
      background: "#C0F8CF",
      padding: "5px 15px 5px 15px",
      borderRadius: "3px",
      border: "1px solid var(--theme-color)",
      marginBottom: "20px",
    },
    dimmed: {
      opacity: 0.5,
    },
    icon: { fontSize: "19px", color: "var(--theme-color)" },
    spacer: { height: "25px" },
    backdrop: {
      zIndex: 1800,
      color: "white",
    },
    canbuy: {
      marginLeft: "auto",
      marginRight: "auto",
      marginTop: "25px",
      maxWidth: "400px",
      textAlign: "center",
      fontSize: "18px",
      fontFamily: "var(--font-normal)",
      lineHeight: "150%",
    },
    canbuyText: {},
    buttonDiv: {
      marginTop: "25px",
      textAlign: "center",
    },
    infoWrapper: {
      paddingLeft: "20px",
      paddingRight: "20px",
      fontFamily: "var(--font-normal)",
      fontSize: "18px",
    },
    infoHeader: {
      fontFamily: "var(--font-bold)",
      paddingBottom: "15px",
    },
    infoText: { paddingBottom: "10px" },
    infoTerms: {
      paddingTop: "20px",
      color: "lightgrey",
      fontFamily: "var(--font-medium)",
      textAlign: "center",
    },
    linkType: {
      fontFamily: "var(--font-medium)",
      color: "var(--theme-color)",
      cursor: "pointer",
    },
  })
);

const Gift = (props: any) => {
  const history = useHistory();
  const classes = useStyles();
  const { tx } = props;

  useEffect(() => {
    setTimeout(() => phaseIn());
  }, []);

  const buyThis = (item: string) => {
    phaseOut();
    setTimeout(() => history.push("/checkout?type=" + item), 150);
  };

  const signUp = () => {
    window.open(
      "https://app.wizzwords.net/login/signup?lang=" + props.language,
      "_blank"
    );
  };

  return (
    <React.Fragment>
      <div className={classes.hero}>
        <div className={classes.bigtitle}>
          {tx("settingsaweaaawewe", "Give WizzWords as a gift!")}
        </div>

        <div className={classes.textsimple}>
          {tx("settingsaaaawwerewe", "WizzWords can be a great gift.")}{" "}
          {tx("jipo2w", "You can gift an unlimited use coupon to anyone.")}{" "}
          {tx(
            "jipo3w",
            "Just buy the coupon and you will receive an email with the coupon which you can give to anybody."
          )}{" "}
          {tx(
            "jipo4",
            "The person only needs to type in their coupon code at sign-up and that's all."
          )}
        </div>
        <div className={classes.flex}>
          <div className={classes.flexleft}>
            <CheckIcon className={classes.icon} />
          </div>
          <div className={classes.flexright}>
            {tx("fawwwsdfirststep12s1", "Very useful gift.")}
          </div>
        </div>
        <div className={classes.flex}>
          <div className={classes.flexleft}>
            <CheckIcon className={classes.icon} />
          </div>
          <div className={classes.flexright}>
            {tx(
              "fasdfirststewerps1asdw",
              "The coupon entitles the user to the full functionality of the WizzWords application without any time limits."
            )}
          </div>
        </div>
        <div className={classes.flex}>
          <div className={classes.flexleft}>
            <CheckIcon className={classes.icon} />
          </div>
          <div className={classes.flexright}>
            {tx(
              "fasdfirststewepwwwsasdf1",
              "An English course from beginner to intermediate level."
            )}
          </div>
        </div>
        <div className={classes.flex}>
          <div className={classes.flexleft}>
            <CheckIcon className={classes.icon} />
          </div>
          <div className={classes.flexright}>
            {tx(
              "fasdfirststepser1asds",
              "You receive a nice email with the coupon which you can either send to the person or simply print out for them."
            )}
          </div>
        </div>
        <div className={classes.text}>
          {tx(
            "fasdaaadasdfirststeps1asdsdfs",
            "Want to read more about the WizzWords method?"
          )}{" "}
          <div
            onClick={() => {
              history.push("/");
            }}
            className={classes.linkDiv}
          >
            {tx("kokooowwe", "Click here")}
          </div>
        </div>

        <div className={classes.packageWrapper}>
          <div className={classes.package}>
            <div className={classes.intWrapper}>
              <div className={classes.length}>
                {tx("monthlylasasdfsdd", "Unlimited use")}
              </div>
              <div className={classes.price}>
                {props.prices.currencySign?.replace(
                  "xxx",
                  props.prices.lifetime?.toLocaleString(props.language || "en")
                )}
              </div>
              <ButtonBase
                className={classes.button}
                onClick={() => buyThis("gift-unlimited")}
              >
                {tx("buyoosd1we", "Order Now")}
              </ButtonBase>
            </div>
          </div>
        </div>
        <div className={classes.canbuy}>
          <div onClick={signUp} className={classes.morelink1}>
            {tx("arawpo3", "Or sign up yourself.")}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Gift;
