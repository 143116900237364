import React from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    textHeader: {
      fontFamily: "var(--font-header)",
      fontWeight: "bold",
      color: "var(--theme-color)",
      fontSize: "42px",
      lineHeight: "150%",
    },
    text: {
      fontFamily: "var(--font-normal)",
      fontSize: "18px",
      lineHeight: "150%",
      marginBottom: "15px",
    },
    body1: {
      fontFamily: "var(--font-normal)",
      fontSize: "18px",
      lineHeight: "150%",
      marginBottom: "15px",
    },
    headline: {
      fontFamily: "var(--font-bold)",
      fontSize: "18px",
      lineHeight: "150%",
      marginBottom: "15px",
      paddingTop: "15px",
    },
    wrapper: {
      maxWidth: "calc(var(--max-width) - 200px)",
      marginLeft: "auto",
      marginRight: "auto",
      width: "100%",
      paddingTop: "80px",
      paddingLeft: "20px",
      paddingRight: "20px",
      paddingBottom: "40px",
    },
    signature: {
      fontFamily: "var(--font-normal)",
      fontVariant: "oblique",
      fontSize: "14px",
      lineHeight: "150%",
      marginBottom: "25px",
    },
    imgdiv: {
      display: "inline-block",
      textAlign: "center",
      width: "fit-content",
      padding: "15px",
      height: "285px",
      marginRight: "20px",
      marginBottom: "15px",
      boxShadow: "0px 1px 1px #ddd, 0 10px 20px #ccc",
    },
    imgWrapper: {
      textAlign: "center",
      marginTop: "15px",
      marginBottom: "15px",
    },
    whyHeader: {
      fontFamily: "var(--font-header)",
      fontWeight: "bold",
      fontSize: "36px",
      lineHeight: "150%",
      marginBottom: "40px",
      color: "var(--theme-color)",
    },
    img: {
      width: "100%",
    },
  })
);

const HomeFaq = (props: any) => {
  const classes = useStyles();

  const { tx } = props;

  return (
    <React.Fragment>
      <div
        className={classes.wrapper}
        style={props.alt ? { paddingTop: "20px" } : {}}
      >
        <div className={classes.whyHeader}>
          {tx("sign12hu2ans", "Answers to questions you might have")}
        </div>
        <div className={classes.headline}>
          {tx("sign12hu2", "What is WizzWords?")}
        </div>
        <div className={classes.text}>
          {tx(
            "sign12hu3",
            "WizzWords is the ultimate addition to any English teaching method and the perfect tool to improve your already existing English knowledge at home."
          )}{" "}
          {tx(
            "sign12hu4",
            "It's purpose is simply to increase your English vocabulary in a somewhat interesting way without rote study."
          )}
        </div>
        <div className={classes.text}>
          {tx(
            "sign12hu5",
            "Additionally WizzWords can help with exercises which you can't really do with your English teacher or in your English class."
          )}
        </div>
        <div className={classes.text}>
          {tx(
            "sign12hu6",
            "It helps you to exercise and improve your pronunciation, and we have lots of drills for practicing important grammar points in English, as well."
          )}
        </div>
        <div className={classes.text}>
          {tx(
            "sign12hu7",
            "You have to learn so much in an English course that those things are better taken care of at home in your own time (which is by the way much cheaper.)"
          )}
        </div>
        <div className={classes.headline}>
          {tx(
            "sign12hu14",
            "How much English do I have to know to use WizzWords?"
          )}
        </div>
        <div className={classes.text}>
          {tx("sign12hu15", "Not much.")}{" "}
          {tx(
            "sign12hu16",
            "WizzWords has five levels of texts you can use for learning."
          )}{" "}
          {tx(
            "sign12hu17",
            "You can already start out after a few weeks of English learning on the first one."
          )}{" "}
          {tx(
            "sign12hu18",
            "Then as you carry on learning English WizzWords will be the ideal place where you add the necessary practice to really get you speaking English and where you make sure you actually remember the words you study for a long time."
          )}
        </div>
        <div className={classes.headline}>
          {tx("sign12hu8", "Is WizzWords a full English language course?")}
        </div>
        <div className={classes.text}>
          {tx("sign12hu9", "No.")}{" "}
          {tx(
            "sign12hu10",
            "WizzWords doesn't try to teach you the basics you can learn in a school or from a conventional English teaching method or from a book."
          )}{" "}
          {tx(
            "sign12hu11",
            "But those courses are also limited as they cannot teach you the thousands of words you will need, or at least you don't have to pay an English teacher to sit with you while you're learning those things."
          )}{" "}
          {tx(
            "sign12hu11a",
            "Also it's easy to forget the words you learn unless you have a very good method to remember them."
          )}{" "}
          {tx(
            "sign12hu12",
            "(That said there are lots of people who learned English with similar methods from scratch without the use of other English courses."
          )}{" "}
          {tx("sign12hu13", "But in our view that's not the recommended way.)")}
        </div>
        <div className={classes.headline}>
          {tx(
            "sign12hu19",
            "Can I use WizzWords for words when I study other English courses?"
          )}
        </div>
        <div className={classes.text}>
          {tx("sign12hu20", "Absolutely.")}{" "}
          {tx("sign12hu21", "In fact that is one of its best uses.")}{" "}
          {tx(
            "sign12hu22",
            "You can add any words to your vocabulary list in WizzWords and it operates as a full blown vocabulary list with the additional bonus that the app itself will tell you when to repeat those words and it will offer example sentences as well to avoid rote repetition of the words."
          )}
        </div>

        <div className={classes.headline}>
          {tx(
            "sign12hu33",
            "Why would I remember words better with WizzWords?"
          )}
        </div>
        <div className={classes.text}>
          {tx(
            "sign12hu34",
            "The worst part of learning languages is the so called forgetting curve."
          )}
        </div>
        <div className={classes.imgWrapper}>
          <div className={classes.imgdiv}>
            <img
              src={
                "/images/" + (props.language || "hu") + "/ForgettingCurve.svg"
              }
              alt="forgetting curve"
            />
          </div>
        </div>
        <div className={classes.text}>
          {tx(
            "sign12hu35",
            "If you just learn something there is a great chance that in one week you will forget most of it."
          )}{" "}
          {tx("sign12hu35a", "This is shown by the red line in our chart.")}
        </div>
        <div className={classes.text}>
          {tx(
            "sign12hu36",
            "Scientists developed a so called 'spaced repetition' method which solves this problem."
          )}{" "}
          {tx(
            "sign12hu36a",
            "It shows you each word you study at specific intervals for review."
          )}{" "}
          {tx(
            "sign12hu36b",
            "The interval is based on a scientific algorithm which proved to be very successful in actually remembering things on the long run."
          )}
        </div>
        <div className={classes.text}>
          {tx(
            "sign12hu37",
            "The green lines in the above chart show the improved results of remembering with this method."
          )}
        </div>
        <div className={classes.text}>
          {tx(
            "sign12hu38",
            "WizzWords uses such a formula called SM-2 in learning words."
          )}
        </div>
        <div className={classes.text}>
          {tx(
            "sign12hu39",
            "It makes sure you don’t waste your precious time and that the energy you put into learning new words actually pays off."
          )}
        </div>
        <div className={classes.headline}>
          {tx("sign12hu23", "I can't understand spoken English.")}{" "}
          {tx("sign12hu24", "Does WizzWords help in that?")}
        </div>
        <div className={classes.text}>
          {tx(
            "sign12hu25",
            "With WizzWords you can listen to every text you read in American English, you can even change the speed of the audio, so yes, it will help you understand spoken English much better."
          )}
        </div>
        <div className={classes.headline}>
          {tx("sign12hu27", "And what is this “3000 most common words” thing?")}
        </div>
        <div className={classes.text}>
          {tx(
            "sign12hu28",
            "In English there are numerous lists about the most used English words."
          )}{" "}
          {tx(
            "sign12hu29",
            " The app is based on a list from Oxford University Press which was put together by language scientists."
          )}{" "}
          {tx(
            "sign12hu31",
            "If you just learn these words you will get a very thorough understanding of the English language and will be able to understand texts and communicate in most situations."
          )}{" "}
          {tx(
            "sign12hu32",
            "And as we concentrate on frequently used words you don't have to study too many infrequent words you will never use."
          )}
        </div>
        <div className={classes.headline}>
          {tx("sign12hu40", "I studied English in the past.")}{" "}
          {tx("sign12hu40a", "How can WizzWords help me?")}
        </div>
        <div className={classes.text}>
          {tx(
            "sign12hu41",
            "There is a positive side of the fact that you can easily forget something with time, namely that with a proper method you can re-learn those things faster and remember them better in the future."
          )}{" "}
          {tx(
            "sign12hu41a",
            "WizzWords uses a special scientific algorithm that brushes up your earlier knowledge and helps to “fix” that knowledge in your memory."
          )}{" "}
          {tx(
            "sign12hu41b",
            "So if you want to refresh your English WizzWords is probably a great choice in itself."
          )}
        </div>

        <div className={classes.headline}>
          {tx("sign12hu42", "Why do you have so many sentences for each word?")}
        </div>
        <div className={classes.text}>
          {tx(
            "sign12hu43",
            "In WizzWords there are over 30,000 example sentences which are translated into your language as well."
          )}{" "}
          {tx(
            "sign12hu43a",
            "It's a scientific fact that words can best be learned in contexts."
          )}{" "}
          {tx(
            "sign12hu43b",
            "And as we provide more than one context for each word the chances are much better that you will remember those words – plus you can use them in all sorts of different situations."
          )}{" "}
        </div>

        {/* <div className={classes.headline}>
          {tx("sign12hu44", "Each word has a definition if I click on it.")}{" "}
          {tx("sign12hu44a", "Are those the right definitions?")}
        </div>
        <div className={classes.text}>
          {tx(
            "sign12hu45",
            "One of the great things in NetEnglish is that we give you the correct definition for each word in that context."
          )}{" "}
          {tx(
            "sign12hu45a",
            "It is often a nightmare to look up words in dictionaries and figure out which definition fits."
          )}{" "}
          {tx(
            "sign12hu45b",
            "In NetEnglish we manually go through every word and attach the right definition to them."
          )}
        </div> */}
        <div className={classes.headline}>
          {tx(
            "sign12hu49c",
            "How often should I use WizzWords to see results?"
          )}
        </div>
        <div className={classes.text}>
          {tx(
            "sign12hu49f",
            "Best would be daily if only for a short time (like 10-15 minutes at least). "
          )}{" "}
          {tx(
            "sign12hu49g",
            "This gives the ideal repeating period for the algorithm to work best."
          )}{" "}
          {tx(
            "sign12hu49h",
            "But you can also use it just to have some fun and look up some words when you have nothing else to do."
          )}{" "}
          {tx("sign12hu49hsd", "Really, every little effort counts.")}{" "}
          {tx(
            "sign12hu49i",
            "Though that would be silly with such a great tool in your hands."
          )}
        </div>
        <div className={classes.headline}>
          {tx("sign12hu50c", "What if I have an idea or feedback for you?")}
        </div>
        <div className={classes.text}>
          {tx("sign12hu50d", "We welcome your feedback at any time.")}{" "}
          {tx("sign12hu50e", "WizzWords is an ever evolving system.")}{" "}
          {tx(
            "sign12hu50f",
            "Our aim is to help people speak better English and if something helps to reach this goal faster it finds its way into our app."
          )}{" "}
          {tx(
            "sign12hu50g",
            "So if you have any ideas or feedback regarding our app, you can always write to helpit@wizzwords.net."
          )}
        </div>
        <div className={classes.headline}>
          {tx("sign12hu51c", "Do you have a trial period?")}
        </div>
        <div className={classes.text}>
          {tx(
            "sign12hu51f",
            "No, but we offer a 14 days, no-questions-asked money back guarantee to make sure you have zero risk in trying out the app."
          )}{" "}
          {tx("sign12hu51g", "And we take that seriously.")}
        </div>
      </div>
    </React.Fragment>
  );
};

export default HomeFaq;
