import React, { useEffect } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core";
import { ButtonBase } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { phaseIn, phaseOut } from "../utils/screen";
import { firestore } from "../firebaseUtil/firebaseUtil";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    buttonDiv: { marginTop: "20px", textAlign: "right" },
    wrapper: {
      marginLeft: "auto",
      marginRight: "auto",
      maxWidth: "var(--max-width)",
      paddingTop: "80px",
    },
    button: {
      background: "var(--theme-color)",
      color: "white",
      fontFamily: "var(--font-medium)",
      fontSize: "18px",
      lineHeight: "125%",
      padding: "14px 22px",
      textTransform: "uppercase",
      borderRadius: "3px",
      marginLeft: "10px",
    },
    bigtitle: {
      paddingBottom: "20px",
      color: "var(--theme-color)",
      fontSize: "36px",
      fontFamily: "Kalam",
    },
    text: {
      paddingBottom: "10px",
      fontSize: "18px",
      fontFamily: "var(--font-normal)",
      lineHeight: "135%",
      flex: "1 1 auto",
    },
  })
);

const GiftFailure = (props: any) => {
  const classes = useStyles();
  const history = useHistory();
  const { tx } = props;

  useEffect(() => {
    document.getElementById("appPaper")?.scrollTo(0, 0);

    const id = document.location.search
      ?.match(/paymentId=[^&]*/)?.[0]
      .replace("paymentId=", "");

    if (!id) {
      history.push("/");
      return;
    }

    firestore.doc("/temporary/" + id).delete();

    setTimeout(() => {
      phaseIn();
    }, 0);
  }, [history]);

  const goContinue = () => {
    phaseOut();
    setTimeout(() => {
      history.push("/");
    }, 150);
  };

  const goBuy = () => {
    phaseOut();
    setTimeout(() => {
      history.push("/buy");
    }, 150);
  };

  return (
    <React.Fragment>
      <div className={classes.wrapper}>
        <div className={classes.bigtitle}>
          {tx("succsdllwlewe", "Oops. What happened?")}
        </div>
        <div className={classes.text}>
          {tx("sweubuowowow", "You cancelled the payment flow.")}
        </div>

        <div className={classes.text}>
          {tx(
            "sldklksdslw",
            "Return to the payment page and try again or go to the dashboard."
          )}
        </div>
        <div className={classes.buttonDiv}>
          <ButtonBase className={classes.button} onClick={goBuy}>
            {tx("ocoasdwentinsdujsl", "Shop")}
          </ButtonBase>
          <ButtonBase className={classes.button} onClick={goContinue}>
            {tx("ocoasdntsdsinsdujsl", "Dashboard")}
          </ButtonBase>
        </div>
      </div>
    </React.Fragment>
  );
};

export default GiftFailure;
