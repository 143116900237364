import React, { useEffect } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core";
import { ButtonBase } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { phaseIn, phaseOut } from "../utils/screen";
import { firestore } from "../firebaseUtil/firebaseUtil";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    buttonDiv: { marginTop: "20px", textAlign: "right" },

    button: {
      background: "var(--theme-color)",
      color: "white",
      fontFamily: "var(--font-medium)",
      fontSize: "18px",
      lineHeight: "125%",
      padding: "14px 22px",
      textTransform: "uppercase",
      borderRadius: "3px",
    },
    bigtitle: {
      paddingBottom: "20px",
      color: "var(--theme-color)",
      fontSize: "21px",
      fontFamily: "var(--font-bold)",
    },
    text: {
      paddingBottom: "10px",
      fontSize: "18px",
      fontFamily: "var(--font-normal)",
      lineHeight: "135%",
      flex: "1 1 auto",
    },
    header: {
      fontSize: "18px",
      fontFamily: "var(--font-bold)",
      lineHeight: "135%",
      textAlign: "center",
    },
    subheader: {
      paddingBottom: "15px",
      fontSize: "16px",
      fontFamily: "var(--font-medium)",
      lineHeight: "135%",
      textAlign: "center",
    },
    cover: {
      maxWidth: "var(--max-width)",
      marginLeft: "auto",
      marginRight: "auto",
      padding: "20px",
      marginTop: "30px",
    },
    coupon: {
      paddingBottom: "5px",
      fontSize: "18px",
      fontFamily: "var(--font-normal)",
      lineHeight: "135%",
      textAlign: "center",
    },
  })
);

const GiftSuccessSignup = (props: any) => {
  const classes = useStyles();
  const history = useHistory();

  const { tx } = props;

  useEffect(() => {
    const checkValid = async () => {
      const id = document.location.search
        ?.match(/id=[^&]*/)?.[0]
        .replace("id=", "");

      if (!id) {
        history.push("/");
        return;
      }

      try {
        const coupons = await firestore.doc("/temporary/" + id).get();
        if (!coupons.exists) {
          history.push("/");
          return;
        }
        await firestore.doc("/temporary/" + id).delete();
        setTimeout(() => {
          phaseIn();
        }, 0);
      } catch (e) {
        console.log("something went wrong");
      }
    };

    document.getElementById("appPaper")?.scrollTo(0, 0);

    checkValid();
  }, [history]);

  const goContinue = () => {
    phaseOut();
    setTimeout(() => {
      history.push("/");
    }, 150);
  };

  return (
    <React.Fragment>
      <div className={classes.cover}>
        <div className={classes.bigtitle}>{tx("succsdllwl", "Success!")}</div>
        <div className={classes.text}>
          {tx("subsduwerowowow", "You successfully bought your access!")}{" "}
        </div>
        <div className={classes.buttonDiv}>
          <ButtonBase className={classes.button} onClick={goContinue}>
            {tx("ocoasdntinsdujsl", "Continue")}
          </ButtonBase>
        </div>
      </div>
    </React.Fragment>
  );
};

export default GiftSuccessSignup;
