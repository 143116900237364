import React, { useEffect } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      marginTop: "0px",
      padding: "25px",
      paddingTop: "0px",
      maxWidth: "var(--max-width)",
      marginLeft: "auto",
      marginRight: "auto",
      marginBottom: "45px",
    },
    text: {
      fontSize: "18px",
      fontFamily: "var(--font-normal)",
      marginBottom: "10px",
      lineHeight: "130%",
    },
    mainHeader: {
      fontSize: "26px",
      fontFamily: "var(--font-header)",
      marginTop: "25px",
      marginBottom: "15px",
    },
    header: {
      fontSize: "24px",
      fontFamily: "var(--font-bold)",
      marginTop: "25px",
      marginBottom: "15px",
    },
  })
);

const Impressum = (props: any) => {
  const classes = useStyles();
  const { tt } = props;

  useEffect(() => {
    if (props.subscription) {
      document.getElementById("subscription")?.scrollIntoView();
    }
  }, [props.subscription]);

  return (
    <React.Fragment>
      <div className={classes.wrapper}>
        <div className={classes.mainHeader}>{tt("sst1acx", "About Us")}</div>
        <div className={classes.text}>
          {tt(
            "t214a",
            "*Name:* NetEnglish Solutions OÜ Hungarian Permanent Establishment."
          )}
        </div>
        <div className={classes.text}>
          {tt(
            "t214aa",
            "*Address:* 9700 Hungary, Szombathely, Deák Ferenc utca 29."
          )}
        </div>
        <div className={classes.text}>
          {tt("t215a", "*Website:* https://netenglish.com")}
        </div>
        <div className={classes.text}>
          {tt("t216a", "*Tax Number:* 27972984-2-18")}
        </div>
        <div className={classes.text}>
          {tt("t217a", "*E-mail:* support@netenglish.com")}
        </div>
        <div className={classes.text}>
          {tt("t218a", "*Company Registry Number:* 18-17-000025")}
        </div>
        <div className={classes.text}>
          {tt("t218aw", "*Registered by:* Szombathely Company Court")}
        </div>
        <div className={classes.text}>
          {tt(
            "t219a",
            "*Hosted by:* Google Cloud Hosting (support@google.com)<br/>Google LLC, 1600 Amphitheatre Parkway, Mountain View, CA 94043 USA"
          )}
        </div>
      </div>
    </React.Fragment>
  );
};

export default Impressum;
