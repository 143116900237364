import React from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    flexWrapper: {
      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap",
      alignItems: "flex-start",
      justifyContent: "center",
      paddingBottom: "40px",
    },
    wrapper: {
      paddingBottom: "30px",
      border: "1px solid #e76659",
      padding: "35px",
      paddingTop: "35px",
      justifyContent: "space-around",
      borderRadius: "15px",
      maxWidth: "920px",
      marginLeft: "10px",
      marginRight: "10px",
      background: "#e76659",
      color: "white",
      position: "relative",
    },
    innerWrapper: {},
    absol: {
      opacity: 0,
      position: "absolute",
      left: 35,
      right: 35,
      top: 35,
      bottom: 35,
      background:
        "linear-gradient(rgba(255,255,255,0.9), rgba(255,255,255,.9)), url(/badge.png)",
      backgroundPosition: "center",
      backgroundRepeat: "no-repeat",
      backgroundSize: "contain",
    },

    heroLeft: {
      position: "relative",
      textAlign: "center",
      maxWidth: "390px",
      minWidth: "310px",
      marginBottom: "40px",
    },
    heroRight: {
      maxWidth: "390px",
      minWidth: "310px",
      position: "relative",
      lineHeight: "150%",
      fontSize: "18px",
      marginLeft: "40px",
      marginRight: "40px",
      "@media (max-width: 820px)": {
        maxWidth: "390px",
      },
      paddingBottom: "40px",
      alignSelf: "center",
    },
    whyHeader: {
      fontSize: "28px",
      lineHeight: 1.5,
      marginBottom: "15px",
      textAlign: "center",
      fontFamily: "var(--font-header)",
      fontWeight: 700,
    },
    text: {
      fontFamily: "var(--font-normal)",
      fontSize: "18px",
      lineHeight: "150%",
    },
    fore: {
      width: "80%",
      minWidth: "120px",
      maxWidth: "160px",
      transform: "translate(-50%,-50%)",
      top: "calc(50% + 20px)",
      left: "50%",
      position: "absolute",
      "@media (min-width: 750px)": {
        width: "80%",
      },
    },
  })
);

const MoneyBack = (props: any) => {
  const classes = useStyles();
  const { tx, language } = props;

  return (
    <React.Fragment>
      <div className={classes.flexWrapper}>
        <div className={classes.heroLeft}>
          <div className={classes.wrapper}>
            <div className={classes.innerWrapper}>
              <div className={classes.absol}></div>
              <div className={classes.whyHeader}>
                {tx("rapo2we", "14 days money back guarantee")}
              </div>
              <div className={classes.text}>
                {tx(
                  "rapo3we",
                  "We are proud of WizzWords and we believe that you'll find it very useful."
                )}{" "}
                {tx(
                  "rweapo3sd",
                  "That's why we offer a 14 day money back guarantee to all customers."
                )}{" "}
                {tx(
                  "rapo4wec",
                  "If you feel, you haven't received what you expected, just write to us and we pay back your money at once."
                )}
              </div>
            </div>
          </div>
        </div>

        <div className={classes.heroRight}>
          <p>
            {tx(
              "dsale11",
              "WizzWords is using 2checkout Ltd. as our authorised reseller."
            )}{" "}
            {tx(
              "sale12",
              "You can pay with bank card, PayPal or bank transfer through their high security page."
            )}
          </p>
          <p>
            {tx(
              "dsale13",
              "After your payment you will receive an email with your username, password and instructions to access the program."
            )}{" "}
            {tx(
              "dsale14",
              "If you run into any problems about the payment process you can write to [[[support@2checkout.com]]] to get help.",
              "",
              "mailto:support@2checkout.com"
            )}
          </p>
          <p>
            {tx(
              "dsale15",
              "If you have any questions or need help, you can write to us at [[[helpxxx@wizzwords.net]]] and we will sort it out for you.",
              language,
              `mailto:help${language}@wizzwords.net`
            )}
          </p>
        </div>
      </div>
    </React.Fragment>
  );
};

export default MoneyBack;
