import React from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    long: { height: "3000px" },
    appPaper: {
      overflow: "hidden auto",
      height: "100vh",
      paddingTop: "50px",
    },
    hero: {
      maxWidth: "var(--max-width)",
      marginLeft: "auto",
      marginRight: "auto",
      display: "flex",
      flexDirection: "row",
      width: "100%",
      flexWrap: "wrap",
      paddingLeft: "20px",
      paddingRight: "20px",
      marginBottom: "50px",
      "&:last-of-type": { paddingBottom: "40px" },
    },
    heroReverse: {
      maxWidth: "var(--max-width)",
      marginLeft: "auto",
      marginRight: "auto",
      display: "flex",
      flexDirection: "row-reverse",
      width: "100%",
      flexWrap: "wrap",
      paddingTop: "50px",
      paddingLeft: "20px",
      paddingRight: "20px",
      marginBottom: "50px",
      "&:last-of-type": { paddingBottom: "40px" },
    },
    heroLeft: {
      flex: "1 1 100%",
      padding: "10px",
      paddingTop: "0px",
      paddingBottom: "0px",
      "@media (min-width: 750px)": {
        flex: "1 1 50%",
        marginBottom: "0px",
      },
    },
    heroRight: {
      flex: "1 1 100%",
      padding: "10px",
      paddingTop: "0px",
      paddingBottom: "0px",
      "@media (min-width: 750px)": {
        flex: "1 1 50%",
      },
      minHeight: "330px",
      position: "relative",
    },
    textHeader: {
      fontFamily: "Kalam, cursive",
      fontSize: "40px",
      lineHeight: 1.5,
      marginBottom: "40px",
    },
    textIntro: {
      fontFamily: "var(--font-normal)",
      fontSize: "18px",
      lineHeight: "150%",
    },
    img: { margin: "20px" },
    button: {
      background: "var(--theme-color)",
      color: "white",
      fontFamily: "var(--font-medium)",
      fontSize: "18px",
      lineHeight: "150%",
      padding: "14px 22px",
      textTransform: "uppercase",
      borderRadius: "3px",
      marginTop: "36px",
    },

    whyHeader: {
      fontFamily: "var(--font-header)",
      fontWeight: "bold",
      fontSize: "30px",
      color: "var(--theme-color)",
      lineHeight: 1.5,
      marginBottom: "45px",
      maxWidth: "var(--max-width)",
      marginLeft: "auto",
      marginRight: "auto",
      width: "100%",
      paddingTop: "90px",
      paddingLeft: "20px",
      paddingRight: "20px",
    },
    foreBig: {
      width: "100%",
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%,-50%) scale(1.1)",
      transformOrigin: "50% 50%",
      maxHeight: "400px",
      "@media (min-width: 450px)": {
        transform: "translate(-50%,-50%) scale(0.9)",
      },
      "@media (min-width: 500px)": {
        transform: "translate(-50%,-50%) scale(0.8)",
      },
      "@media (min-width: 750px)": {
        transform: "translate(-50%,-50%) scale(1.1)",
      },
      "@media (min-width: 1000px)": {
        transform: "translate(-50%,-50%) scale(1.0)",
      },

      "@media (min-width: 1100px)": {
        transform: "translate(-50%,-50%) scale(0.9)",
      },
    },
    foreSmall: {
      width: "100%",
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%,-50%) scale(1.4)",
      transformOrigin: "50% 50%",
      maxHeight: "450px",
      "@media (min-width: 400px)": {
        transform: "translate(-50%,-50%) scale(1.4)",
      },
      "@media (min-width: 500px)": {
        transform: "translate(-50%,-50%) scale(1.2)",
      },
      "@media (min-width: 600px)": {
        transform: "translate(-50%,-50%) scale(0.9)",
      },
      "@media (min-width: 750px)": {
        transform: "translate(-50%,-50%) scale(1.5)",
      },
      "@media (min-width: 1000px)": {
        transform: "translate(-50%,-50%) scale(1.3)",
      },
    },
    patch: {
      position: "absolute",
      transform: "translate(-40%,-50%)",
      top: "50%",
      left: "50%",
    },
    patchReverse: {
      position: "absolute",
      transform: "translate(-50%,-50%)",
      top: "50%",
      left: "50%",
    },
    flex: {
      display: "flex",
      width: "100%",
      marginBottom: "10px",
    },
    flexleft: { flex: "0 0 40px", color: "rgb(231, 102, 89)" },
    flexright: { flex: "1 1 auto" },
    smallheader: {
      fontFamily: "var(--font-medium)",
      fontSize: "18px",
      lineHeight: "150%",
      paddingBottom: "5px",
    },
    smalltext: {
      fontFamily: "var(--font-normal)",
      fontSize: "18px",
      lineHeight: "150%",
    },
  })
);

export const Check = () => {
  return (
    <svg
      className="tcb-icon"
      viewBox="0 0 512 512"
      data-id="icon-check-circle-solid"
      data-name=""
    >
      <path d="M504 256c0 136.967-111.033 248-248 248S8 392.967 8 256 119.033 8 256 8s248 111.033 248 248zM227.314 387.314l184-184c6.248-6.248 6.248-16.379 0-22.627l-22.627-22.627c-6.248-6.249-16.379-6.249-22.628 0L216 308.118l-70.059-70.059c-6.248-6.248-16.379-6.248-22.628 0l-22.627 22.627c-6.248 6.248-6.248 16.379 0 22.627l104 104c6.249 6.249 16.379 6.249 22.628.001z"></path>
    </svg>
  );
};

const Init = (props: any) => {
  const classes = useStyles();

  const { language } = props;

  return (
    <React.Fragment>
      <div className={classes.whyHeader}>{props.args.title}</div>
      <div className={classes.hero}>
        <div className={classes.heroLeft}>
          <div className={classes.flex}>
            <div className={classes.flexleft}>
              <Check />
            </div>
            <div className={classes.flexright}>
              <div className={classes.smallheader}>{props.args.t0}</div>
              <div className={classes.smalltext}>{props.args.e0}</div>
            </div>
          </div>
          <div className={classes.flex}>
            <div className={classes.flexleft}>
              <Check />
            </div>
            <div className={classes.flexright}>
              <div className={classes.smallheader}>{props.args.t1}</div>
              <div className={classes.smalltext}>{props.args.e1}</div>
            </div>
          </div>
          <div className={classes.flex}>
            <div className={classes.flexleft}>
              <Check />
            </div>
            <div className={classes.flexright}>
              <div className={classes.smallheader}>{props.args.t2}</div>
              <div className={classes.smalltext}>{props.args.e2}</div>
            </div>
          </div>
        </div>
        <div className={classes.heroRight}>
          <img
            alt="phone"
            className={classes.foreSmall}
            src={`/images/${language}/phone1b.png`}
          />
        </div>
      </div>
      <div className={classes.heroReverse}>
        <div className={classes.heroLeft}>
          <div className={classes.flex}>
            <div className={classes.flexleft}>
              <Check />
            </div>
            <div className={classes.flexright}>
              <div className={classes.smallheader}>{props.args.t3}</div>
              <div className={classes.smalltext}>{props.args.e3}</div>
            </div>
          </div>
          <div className={classes.flex}>
            <div className={classes.flexleft}>
              <Check />
            </div>
            <div className={classes.flexright}>
              <div className={classes.smallheader}>{props.args.t4}</div>
              <div className={classes.smalltext}>{props.args.e4}</div>
            </div>
          </div>
          <div className={classes.flex}>
            <div className={classes.flexleft}>
              <Check />
            </div>
            <div className={classes.flexright}>
              <div className={classes.smallheader}>{props.args.t5}</div>
              <div className={classes.smalltext}>{props.args.e5}</div>
            </div>
          </div>
        </div>
        <div className={classes.heroRight}>
          <img
            alt="phone"
            className={classes.foreBig}
            src={`/images/${language}/phone1a.png`}
          />
        </div>
      </div>
      <div className={classes.hero}>
        <div className={classes.heroLeft}>
          <div className={classes.flex}>
            <div className={classes.flexleft}>
              <Check />
            </div>
            <div className={classes.flexright}>
              <div className={classes.smallheader}>{props.args.t6}</div>
              <div className={classes.smalltext}>{props.args.e6}</div>
            </div>
          </div>
          <div className={classes.flex}>
            <div className={classes.flexleft}>
              <Check />
            </div>
            <div className={classes.flexright}>
              <div className={classes.smallheader}>{props.args.t7}</div>
              <div className={classes.smalltext}>{props.args.e7}</div>
            </div>
          </div>
          <div className={classes.flex}>
            <div className={classes.flexleft}>
              <Check />
            </div>
            <div className={classes.flexright}>
              <div className={classes.smallheader}>{props.args.t8}</div>
              <div className={classes.smalltext}>{props.args.e8}</div>
            </div>
          </div>
        </div>
        <div className={classes.heroRight}>
          <img
            alt="phone"
            className={classes.foreSmall}
            src={`/images/${language}/imagea4.png`}
          />
        </div>
      </div>
    </React.Fragment>
  );
};

export default Init;
