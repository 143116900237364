import React from "react";
import { ButtonBase } from "@material-ui/core";
import { makeStyles, Theme, createStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    origButton: { textAlign: "center" },
    button: {
      background: "var(--theme-color)",
      color: "white",
      fontFamily: "var(--font-medium)",
      fontSize: "18px",
      lineHeight: "150%",
      padding: "14px 22px",
      textTransform: "uppercase",
      borderRadius: "3px",
      marginTop: "10px",
      marginBottom: "45px",
    },
  })
);

const SignupButton = (props: any) => {
  const classes = useStyles();

  const signup = () => {
    window.open(
      "https://admin.wizzwords.net/login/signup?lang=" + props.language,
      "_blank"
    );
  };

  return (
    <React.Fragment>
      <div className={classes.origButton}>
        <ButtonBase className={classes.button} onClick={signup}>
          {props.text || props.tx("reshkl3weas", "Sign Up")}
        </ButtonBase>
      </div>
    </React.Fragment>
  );
};

export default SignupButton;
