import React, { useEffect, useRef, useState } from "react";
import { makeStyles, Theme, createStyles, ButtonBase } from "@material-ui/core";

import Arguments from "./homepage.arc";
import Patch1 from "./patch1";
import Patch2 from "./patch2";

import { phaseIn } from "../utils/screen";
import Init from "./homepage.init";
import OrigButton from "./homepage.gobutton";
import ResellSign from "./homepage.resellSign";
import SignupButton from "./homepage.signup";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    long: { height: "3000px" },
    morelink1: {
      marginTop: "25px",
      color: "grey",
      fontFamily: "var(--font-normal)",
      fontSize: "18px",
      cursor: "pointer",
      textAlign: "center",
      marginBottom: "80px",
    },

    hero: {
      maxWidth: "var(--max-width)",
      marginLeft: "auto",
      marginRight: "auto",
      display: "flex",
      width: "100%",
      flexWrap: "wrap",
      paddingTop: "50px",
      paddingLeft: "20px",
      paddingRight: "20px",
      paddingBottom: "30px",
    },
    heroLeft: {
      flex: "1 1 100%",
      padding: "10px",
      paddingTop: "40px",
      "@media (min-width: 750px)": {
        flex: "1 1 50%",
      },
    },
    heroRight: {
      flex: "1 1 100%",
      "@media (min-width: 750px)": {
        flex: "1 1 50%",
      },
      marginTop: "40px",
      textAlign: "center",
      position: "relative",
      paddingTop: "15px",
    },
    textHeader: {
      fontFamily: "var(--font-header)",
      fontWeight: "bold",
      fontSize: "25px",
      lineHeight: "150%",
      marginBottom: "40px",
      textAlign: "center",
      "@media (max-width: 340px)": {
        fontSize: "22px",
        marginBottom: "25px",
      },
    },
    heroTop: {
      fontFamily: "var(--font-header)",
      fontWeight: "bold",
      color: "var(--theme-color)",
      fontSize: "52px",
      lineHeight: "150%",
      marginBottom: "50px",
      textAlign: "center",
      marginTop: "50px",
      "@media (max-width: 750px)": {
        fontSize: "39px",
        marginBottom: "30px",
        marginTop: "30px",
      },
      "@media (max-width: 340px)": {
        fontSize: "30px",
        marginBottom: "0px",
        marginTop: "30px",
      },
    },
    textIntro: {
      fontFamily: "var(--font-normal)",
      fontSize: "18px",
      lineHeight: "150%",
      marginTop: "15px",
    },
    img: { margin: "20px" },
    origButton: { textAlign: "center" },
    button: {
      background: "var(--theme-color)",
      color: "white",
      fontFamily: "var(--font-medium)",
      fontSize: "18px",
      lineHeight: "150%",
      padding: "14px 22px",
      textTransform: "uppercase",
      borderRadius: "3px",
      marginTop: "36px",
    },
    summary: {
      background: "#FFF4EB",
      width: "100%",
      minHeight: "200px",
    },
    packageWrapper: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-around",
      flexWrap: "wrap",
      alignItems: "center",
      maxWidth: "var(--max-width)",

      "@media (min-width: 660px)": {
        display: "block",
        marginLeft: "auto",
        marginRight: "auto",
      },
      "@media (min-width: 800px)": {
        display: "flex",
        marginLeft: "auto",
        marginRight: "auto",
      },
    },
    package: {
      flex: "0 0 290px",
      marginLeft: "10px",
      marginRight: "10px",
      marginTop: "20px",
      marginBottom: "10px",
      backgroundImage: 'url("/painted_patch_rect_01.png")',
      backgroundSize: "100% 100%",
      minHeight: "180px",
      width: "290px",
      borderRadius: "5px",
      "@media (min-width: 660px)": {
        marginLeft: "auto",
        marginRight: "auto",
      },
      "@media (min-width: 800px)": {
        width: "240px",
        flex: "0 0 220px",
        marginLeft: "auto",
        marginRight: "auto",
      },
    },
    whyHeader: {
      fontFamily: "var(--font-header)",
      fontWeight: "bold",
      fontSize: "36px",
      lineHeight: 1.5,
      marginBottom: "40px",
    },
    fore: {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%,-50%) scale(0.5)",
      transformOrigin: "50% 50%",
    },
    patch: {
      position: "absolute",
    },
    fabIcon: {
      marginRight: theme.spacing(1),
    },
  })
);

const arg1 = (tx: any) => ({
  title: tx("resjkl1asaw", "The app that's yours to sell"),
  t1: tx("jkl2", "Over 300 lessons"),
  e1: tx(
    "resjkl3wew",
    "You can customize the app to include your name or company and your contact information."
  ),

  t2: tx("jkl4", "One application"),
  e2: tx(
    "resjkl5wew",
    "You send out our prewritten emails personalized a bit for your students."
  ),
  t3: tx("jkl4weewsc", "One application"),
  e3: tx("resjkl5wewcm", "You earn 16 Euros after each app sold."),
});

const arg2 = (tx: any, language = "it") => ({
  title: tx("resjkm1aasdw", "Is the app good for your students?"),
  e1: tx(
    "resjkm3asd",
    "This is a very popular vocabulary building tool with a lot of extra functions (pronuncation, writing and grammar drills)."
  ),

  e2: tx(
    "resjkm5werw",
    "It also keeps track of words learned and the student can practice through scientific algorithms."
  ),

  e3: tx(
    "resjkm7asd",
    "[[[Read all about the app here.]]]",
    "",
    "https://wizzwords.net/" + language
  ),
});

const arg5 = (tx: any) => ({
  title: tx("resjkp2awewe", "Why is it good for you?"),

  t0: tx("resjkp3a1", "You help your students"),
  e0: tx(
    "resjkp4a1",
    "By experience your students will like it, and you earn goodwill. Reviews of the app are very good."
  ),
  t1: tx("resjkp3a10", "You earn easy money."),
  e1: tx("resjkp4a10", "For each app your students buy you can get 15 Euros."),

  t2: tx("resjkp7a1", "You can have your 'own' app."),
  e2: tx(
    "resjkp8a1",
    "The app itself is customizable and it can promote you or your company."
  ),

  t3: tx("resjkp7a2", "Minimal work for you"),
  e3: tx(
    "resjkp8a2",
    "We handle customer service and everything else. For you it’s a fire and forget experience."
  ),
  t4: tx("resjkp7a3", "Not a competition for you"),
  e4: tx(
    "resjkp8a3",
    "It doesn’t replace any language teaching method and we are not competing for your students and customers."
  ),

  t5: tx("resjkp7a4", "Your customers, not ours"),
  e5: tx(
    "resjkp8a4",
    "We do no further marketing toward people who buy through you."
  ),
  t6: tx("resjkp7a4we", "Uniquely useful features"),
  e6: tx(
    "resjkp8a4asdqq",
    "Ultimately the app is just cool and helps your students and customers."
  ),
  t7: tx("resjkp7a4op", "Easy homework"),
  e7: tx(
    "resjkp8a4cwop",
    "Your students can fill their time with useful activity. As the app is fun they will work more and more."
  ),
  t8: tx("resjkp7a4xx", "Utilize your contacts"),
  e8: tx(
    "resjkp8a4ccc",
    "You have a lot of financial potential in the contacts you built up. This is a very simple way to utilize that potential."
  ),
});

const argVideo = (tx: any) => ({
  title: tx("jkp2awewewe", "What if I have a problem?"),

  t0: tx("jkp3a10c", "One-click help sections"),
  e0: tx(
    "jkp4a10c",
    "The application comes with a detailed help function. Just find the little question mark in a circle on the page and click on it, it will tell you how to use the functions on that page."
  ),
  t1: tx("jkp3a1c", "Introductory Video"),
  e1: tx(
    "jkp4a1aclw",
    "Once you start using the app, you will watch a simple video which will introduce the main functions of the application to help you get off to a good start."
  ),

  t2: tx("jkp5a1c", "Costumer service also on weekends"),
  e2: tx(
    "jkp6a1c",
    "The FAQ (Frequently Asked Questions) option from the menu will give you the answer to most possible problems. But if you have any questions, just write to us on the provided email address, and we will answer you even on weekends."
  ),

  t3: tx("jkp7a2c", "14 day money back guarantee"),
  e3: tx(
    "jkp8a2c",
    "If you're not satisfied with the application for any reason, just let us know within 14 days and we refund your money without any questions"
  ),
});

const isSafari = () => {
  const ua = navigator.userAgent.toLowerCase();
  return ua.indexOf("safari") > -1 && ua.indexOf("chrome") < 0;
};

let loaded = false;

const Reseller = (props: any) => {
  const classes = useStyles();

  const { tx, language } = props;

  useEffect(() => {
    setTimeout(() => phaseIn());

    localStorage.setItem("reseller", "yes");
  }, []);

  useEffect(() => {
    if (language && loaded) {
      (videoParentRef.current?.children[0] as any)?.load();
      // (document.getElementById("thisvideo") as any).load();
    }
    if (language) {
      loaded = true;
    }
  }, [language]);

  // const showMore = () => {
  //   phaseOut();
  //   setTimeout(() => history.push("/moreinfo"));
  // };

  const moveToShopping = () => {
    document
      .getElementById("buybutton")
      ?.scrollIntoView({ behavior: "smooth", block: "center" });
  };

  const videoParentRef = useRef<HTMLDivElement>(null);
  const [shouldUseImage, setShouldUseImage] = useState(false);
  useEffect(() => {
    // check if user agent is safari and we have the ref to the container <div />
    if (isSafari() && videoParentRef.current) {
      // obtain reference to the video element
      // @ts-ignore
      const player = videoParentRef.current?.children[0] as any;

      // if the reference to video player has been obtained
      if (player) {
        // set the video attributes using javascript as per the
        // webkit Policy
        player.controls = false;
        player.playsInline = true;
        player.muted = true;
        player.setAttribute("muted", ""); // leave no stones unturned :)
        player.autoplay = true;

        // Let's wait for an event loop tick and be async.
        setTimeout(() => {
          // player.play() might return a promise but it's not guaranteed crossbrowser.
          const promise = player.play();
          // let's play safe to ensure that if we do have a promise
          if (promise.then) {
            promise
              .then(() => {})
              .catch(() => {
                // if promise fails, hide the video and fallback to <img> tag
                // @ts-ignore
                videoParentRef.current.style.display = "none";
                setShouldUseImage(true);
              });
          }
        }, 0);
      }
    }
  }, []);

  return (
    <React.Fragment>
      <div className={classes.hero}>
        <div className={classes.heroTop}>
          {tx("reshkl1abasda1", "Help your English students and customers...")}
          <br />
          {tx("reshkl1abasda1a", "and earn easy money.")}
        </div>
        <div className={classes.heroLeft}>
          <div className={classes.textHeader}>
            {tx(
              "reshkl2cb1",
              "You have students and customers... we have a Vocabulary building and practice application you can offer to them to help with their studies."
            )}
          </div>
          <div className={classes.textIntro}>
            {tx(
              "reshkl2cb1a1",
              "Now you can offer it to them as your own and earn 60% of the price of it with as little as 30 minutes invested."
            )}
          </div>
          <div className={classes.textIntro}>
            {tx(
              "reshkl2cb1a1a",
              "Not mentioning the goodwill you earn as the app will help them a lot."
            )}
          </div>
          <div className={classes.textIntro}>
            {tx("reswehkl2cb1a1a", "Read on if you are interested.")}
          </div>

          <div className={classes.origButton}>
            <ButtonBase
              ref={props.buttonRef}
              className={classes.button}
              onClick={moveToShopping}
            >
              {tx("reshkl3as", "I'm in already! Show me how!")}
            </ButtonBase>
          </div>
        </div>

        {shouldUseImage ? (
          <div className={classes.heroRight}>
            <img
              src={`/images/${language}/SZKV-WEB-HB.mp4`}
              alt="Muted Video"
              data-aos="fade-up"
            />
          </div>
        ) : (
          <div
            className={classes.heroRight}
            ref={videoParentRef}
            dangerouslySetInnerHTML={{
              __html: `
        <video
          style="width: 80%;"
          loop
          muted
          autoplay
          controls
          controlslist="nodownload"
          playsinline
          preload="metadata"
        >
        <source src="/images/${language}/SZKV-WEB-HB.mp4" type="video/mp4" />
        </video>`,
            }}
          />
        )}
      </div>
      {/* <Banner tx={tx} /> */}
      <Arguments
        reverse={true}
        patch={<Patch1 />}
        args={arg1(tx)}
        tx={tx}
        pic={`/images/${language}/multiple.png`}
      />
      <Arguments
        patch={<Patch2 />}
        args={arg2(tx, language)}
        tx={tx}
        pic={`/images/${language}/pic1.png`}
        skewed={true}
      />
      <OrigButton tx={tx} text={tx("oweklasd903we4", "I'm in. Let's start.")} />
      {/* <Experience tx={tx} /> */}
      {/* <SuccessesSmall tx={tx} /> */}
      <Init patch={<Patch2 />} args={arg5(tx)} tx={tx} language={language} />
      {/* <OrigButton tx={tx} /> */}
      {/* <HomeVideo tx={tx} language={language} /> */}
      <ResellSign tx={tx} language={language} />
      <SignupButton tx={tx} language={language} />
      {/* <MoneyBack tx={tx} language={language} />
      <Successes tx={tx} />

      <HomeFaq tx={tx} language={language} />
      <OrigButton tx={tx} language={language} />
      <Problems tx={tx} args={argVideo(tx)} />
      <NetEnglish tx={tx} />
      <OrigButton tx={tx} /> */}
    </React.Fragment>
  );
};

export default Reseller;
