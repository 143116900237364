import React, { useState } from "react";
import {
  makeStyles,
  Theme,
  createStyles,
  FormControl,
  InputLabel,
  Input,
  FormHelperText,
} from "@material-ui/core";
import { Visibility, VisibilityOff } from "@material-ui/icons";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    textField: {
      flex: "1 1 0px",
      marginTop: "8px",
      marginRight: "10px",
      "@media (max-width: 400px)": {
        flex: "1 1 auto",
        width: "100%",
      },
    },
  })
);

const BuyTextForm = (props: any) => {
  const classes = useStyles();
  const [visibility, setVisibility] = useState(false);

  return (
    <React.Fragment>
      <FormControl fullWidth className={classes.textField}>
        <InputLabel htmlFor="component-firstName">{props.label}</InputLabel>
        <Input
          error={props.error && props.submitted}
          required={!!props.required}
          className={classes.textField}
          id={props.name}
          endAdornment={
            props.more?.type === "password" ? (
              !visibility ? (
                <Visibility
                  fontSize="small"
                  onClick={() => setVisibility(true)}
                />
              ) : (
                <VisibilityOff
                  fontSize="small"
                  onClick={() => setVisibility(false)}
                />
              )
            ) : null
          }
          value={props.data[props.name] || ""}
          onChange={props.handleChange}
          {...(visibility
            ? {
                type: "text",
                autoComplete: "off",
                autoCorrect: "off",
                autoCapitalize: "off",
                spellCheck: "false",
              }
            : props.more)}
        />
        {props.email ? (
          <FormHelperText id="my-helper-text">
            {props.emailExists
              ? "Ilyen emailcímmel már van felhasználó."
              : "Emailcímet gondosan ellenőrizd!"}
          </FormHelperText>
        ) : (
          ""
        )}
        {props.more?.type === "password" ? (
          <FormHelperText id="my-helper-text">
            {props.visibility
              ? "Kattints a kis szemre, hogy lásd a jelszót"
              : "Kattints a kis szemre, hogy elrejtsd a jelszót"}
          </FormHelperText>
        ) : (
          ""
        )}
      </FormControl>
    </React.Fragment>
  );
};

export default BuyTextForm;
