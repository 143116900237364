import React, { useEffect } from "react";
import {
  makeStyles,
  Theme,
  createStyles,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@material-ui/core";
import { phaseIn } from "../utils/screen";
// import { loadStripe } from "@stripe/stripe-js";
// // Make sure to call `loadStripe` outside of a component’s render to avoid
// // recreating the `Stripe` object on every render.
// const stripePromise = loadStripe(
//   "pk_test_51I0aXALSmpZDscoFmLNNOPD8h2GkbUNTR7eUq8Nxez5MEDb3VYOfc3AKX0HqkVzG8uhCm2u6B50b90xMTMDrLI3s00o3ZncGor"
// ) as any;

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    textHeader: {
      fontFamily: "var(--font-header)",
      fontWeight: "bold",
      fontSize: "40px",
      lineHeight: 1,
      marginBottom: "50px",
      textAlign: "center",
    },
    para: {
      marginBottom: "8px",
    },
    body: {
      fontFamily: "var(--font-normal)",
      fontSize: "18px",
      lineHeight: "150%",
      marginBottom: "15px",
    },
    body1: {
      fontFamily: "var(--font-normal)",
      fontSize: "18px",
      lineHeight: "150%",
      marginBottom: "15px",
    },
    subtitle: {
      fontFamily: "var(--font-bold)",
      fontSize: "21px",
      lineHeight: "150%",
      marginBottom: "15px",
      marginTop: "30px",
    },
    email: {
      textAlign: "center",
      fontFamily: "var(--font-bold)",
      fontSize: "18px",
      lineHeight: "150%",
      paddingTop: "15px",
      color: "darkblue",
    },
    wrapper: {
      maxWidth: "900px",
      marginLeft: "auto",
      marginRight: "auto",
      width: "100%",
      paddingTop: "80px",
      paddingLeft: "20px",
      paddingRight: "20px",
      paddingBottom: "40px",
      lineHeight: "1.5",
      fontSize: "18px",
    },
    signature: {
      fontFamily: "var(--font-normal)",
      fontVariant: "oblique",
      fontSize: "14px",
      lineHeight: "150%",
      marginBottom: "25px",
    },
    imgdiv: {
      width: "200px",
      height: "285px",
      float: "left",
      marginRight: "20px",
      marginBottom: "15px",
      boxShadow: "0px 10px 1px #ddd, 0 10px 20px #ccc",
      "@media (max-width: 360px)": { width: "150px", height: "213px" },
    },
    img: {
      width: "100%",
    },
    normal: { fontFamily: "var(--font-normal)" },
    medium: { fontFamily: "var(--font-medium)" },
    link: { color: "var(--theme-color)" },
  })
);

const Faq = (props: any) => {
  const classes = useStyles();

  const { tx } = props;

  useEffect(() => {
    setTimeout(() => phaseIn());
  }, []);

  const [expanded, setExpanded] = React.useState<string | false>("panel1");
  //  const [expanded1, setExpanded1] = React.useState<string | false>("panel1");

  const handleChange =
    (panel: string) => (event: React.ChangeEvent<{}>, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
    };

  // const handleClick = async (event: any) => {
  //   // When the customer clicks on the button, redirect them to Checkout.
  //   const stripe = await stripePromise;
  //   await stripe.redirectToCheckout({
  //     lineItems: [
  //       {
  //         price: "price_1I6GOyLSmpZDscoFNDctDiZj", // Replace with the ID of your price
  //         quantity: 1,
  //       },
  //     ],
  //     mode: "payment",
  //     successUrl: "https://netenglish.com/success",
  //     cancelUrl: "https://netenglish.com/cancel",
  //   });
  //   // If `redirectToCheckout` fails due to a browser or network
  //   // error, display the localized error message to your customer
  //   // using `error.message`.
  // };

  // const handleChange1 = (panel: string) => (
  //   event: React.ChangeEvent<{}>,
  //   newExpanded: boolean
  // ) => {
  //   setExpanded1(newExpanded ? panel : false);
  // };

  return (
    <React.Fragment>
      <div className={classes.wrapper}>
        <div className={classes.textHeader}>
          {tx("faq0", "Frequently Asked Questions")}
        </div>
        <div className={classes.body}>
          {tx(
            "faqas10a",
            "If you have questions regarding the functions of the program best is to click on the ? icon in the app and you will see a full description of the current function."
          )}{" "}
          {tx(
            "afaqas0a",
            "If you have a technical problem, please review the below questions and answers quickly as it may already cover your problem, and you don't have to wait for an answer."
          )}{" "}
          {tx(
            "bafaqas0",
            "And if you don't find the answer write to the below email address:"
          )}
        </div>
        <div className={classes.email}>
          <a
            className={classes.link}
            href={`mailto:${props.support}`}
            rel="noopener noreferrer"
            target="_blank"
          >
            {props.support}
          </a>
        </div>
        <div className={classes.subtitle}>
          {tx("asdwoowos", "Technical Points")}
        </div>
        <Accordion
          square
          expanded={expanded === "panel1"}
          onChange={handleChange("panel1")}
        >
          <AccordionSummary
            aria-controls="panel1d-content"
            id="panel1d-header"
            className={classes.medium}
          >
            <div className={classes.medium}>
              {tx("jkpo2q", "I haven't received the login data for WizzWords.")}
            </div>
          </AccordionSummary>
          <AccordionDetails>
            <div className={classes.normal}>
              <div className={classes.para}>
                {tx(
                  "jkpo3q",
                  "When you buy WizzWords you receive an e-mail with your invoice and another one with your WizzWords login data."
                )}
              </div>
              <div className={classes.para}>
                {tx(
                  "jkpo4q",
                  "Please check in your Spam folder as well if you can't find it."
                )}
              </div>
              <div className={classes.para}>
                {tx(
                  "jkpo4aq",
                  "If you can't find it, go to [[[https://app.wizzwords.net/]]] using Chrome or Safari as your browser.",
                  "",
                  "https://app.wizzwords.net/?lang=" + props.language
                )}
              </div>
              <div className={classes.para}>
                {tx(
                  "jkpo4bq",
                  "When you're on the login screen click on the *I forgot my password* option and follow the instructions."
                )}
              </div>
              <div className={classes.para}>
                {tx(
                  "jkpo4baq",
                  "If the computer doesn't recognize your e-mail address write to the above support e-mail address with exact data about your purchase."
                )}{" "}
                {tx(
                  "jkpo4bacq",
                  "The most usual problem is that your e-mail address was incorrectly entered when you bought the app which we can correct easily."
                )}
              </div>
            </div>
          </AccordionDetails>
        </Accordion>
        <Accordion
          square
          expanded={expanded === "panel2"}
          onChange={handleChange("panel2")}
        >
          <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
            <div className={classes.medium}>
              {tx("jkpo5q", "I want to change my password.")}
            </div>
          </AccordionSummary>
          <AccordionDetails>
            <div className={classes.normal}>
              <div className={classes.para}>
                {tx(
                  "jkpo6q",
                  "To change your password make sure you're not logged in."
                )}{" "}
                {tx(
                  "jkpo6aq",
                  "If you're logged in, log out first from the menu on the Dashboard (three horizontal lines in the top right corner)."
                )}
              </div>
              <div className={classes.para}>
                {tx(
                  "jkpo6aqasd",
                  "On the login screen click on the *I forgot my password* option and follow the instructions."
                )}
              </div>
            </div>
          </AccordionDetails>
        </Accordion>
        <Accordion
          square
          expanded={expanded === "panel3"}
          onChange={handleChange("panel3")}
        >
          <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
            <div className={classes.medium}>
              {tx("jkpo7q", "How can I change my E-mail address?")}
            </div>
          </AccordionSummary>
          <AccordionDetails>
            <div className={classes.normal}>
              <div className={classes.para}>
                {tx(
                  "jkpo8q",
                  "If you want to change the E-mail address you use for the app write an e-mail to the above support e-mail address with the exact data, the original e-mail address and the new e-mail address."
                )}{" "}
              </div>
              <div className={classes.para}>
                {tx(
                  "jkpo9q",
                  "You have to send the e-mail from the original E-mail address you want to change."
                )}
              </div>
            </div>
          </AccordionDetails>
        </Accordion>
        <Accordion
          square
          expanded={expanded === "panel4"}
          onChange={handleChange("panel4")}
        >
          <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
            <div className={classes.medium}>
              {tx("jkpo11q", "How can I watch the Introductory Video?")}
            </div>
          </AccordionSummary>
          <AccordionDetails>
            <div className={classes.normal}>
              <div className={classes.para}>
                {tx(
                  "jkpo12q",
                  "You can watch it on this website on the Home Page or in the app you can choose First Steps from the menu."
                )}{" "}
              </div>
            </div>
          </AccordionDetails>
        </Accordion>
        <Accordion
          square
          expanded={expanded === "panel5"}
          onChange={handleChange("panel5")}
        >
          <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
            <div className={classes.medium}>
              {tx("jkpo17q", "Something else doesn't work...")}
            </div>
          </AccordionSummary>
          <AccordionDetails>
            <div className={classes.normal}>
              {tx(
                "jkpo18q",
                "Write an e-mail to the above address with all details."
              )}{" "}
              {tx(
                "jkpo19q",
                "Please include what browser are you using and what type of device."
              )}{" "}
              {tx(
                "jkpo20q",
                "Also write down the details of what you're doing and what's happening as a result, and what should happen."
              )}
            </div>
          </AccordionDetails>
        </Accordion>
      </div>
    </React.Fragment>
  );
};

export default Faq;
