import React, { useEffect, useState } from "react";
import {
  makeStyles,
  Theme,
  createStyles,
  List,
  ListItemIcon,
  ListItemText,
  Divider,
  ListItem,
  Drawer,
} from "@material-ui/core";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import { useLocation, useHistory } from "react-router-dom";
import HomeIcon from "@material-ui/icons/Home";
import { phaseOut } from "../utils/screen";
import { MonetizationOn } from "@material-ui/icons";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    list: { minWidth: "210px" },
    menuItem: {
      fontFamily: "var(--font-normal)",
      fontSize: "18px",
    },
    activeMenuItem: {
      fontSize: "18px",
      color: "var(--theme-color)",
      fontFamily: "var(--font-bold)",
    },
    img: {},
  })
);

const DrawerMine = (props: any) => {
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();
  const [resell, setResell] = useState(false);

  const { tx } = props;

  useEffect(() => {
    const b = localStorage.getItem("reseller");

    if (b) {
      setResell(true);
    }
  }, [location.pathname]);

  const getActive = () => {
    if (location.pathname.indexOf("/pricing") > -1) {
      return 1;
    }

    if (location.pathname.indexOf("/moreinfo") > -1) {
      return 2;
    }

    if (location.pathname.indexOf("/support") > -1) {
      return 3;
    }

    if (location.pathname.indexOf("/gift") > -1) {
      return 4;
    }

    if (location.pathname.indexOf("/reseller") > -1) {
      return 5;
    }

    return 0;
  };

  const sel = getActive();

  const routeMe = (route: string) => {
    props.setDrawerOpen(false);
    phaseOut();
    setTimeout(() => {
      history.push(route);
    }, 150);
  };

  return (
    <React.Fragment>
      <Drawer
        anchor={"right"}
        open={props.drawerOpen}
        onClose={() => props.setDrawerOpen(false)}
      >
        <List className={classes.list}>
          <ListItem button onClick={() => routeMe("/" + props.language)}>
            <ListItemIcon>
              <HomeIcon />
            </ListItemIcon>
            <ListItemText
              primary={tx("jhpo2", "Home")}
              className={sel === 0 ? classes.activeMenuItem : classes.menuItem}
            />
          </ListItem>
          {resell ? (
            <ListItem
              button
              onClick={() => routeMe("/" + props.language + "/reseller")}
            >
              <ListItemIcon>
                <MonetizationOn />
              </ListItemIcon>
              <ListItemText
                primary={tx("resjhpo2", "Resell")}
                className={
                  sel === 5 ? classes.activeMenuItem : classes.menuItem
                }
              />
            </ListItem>
          ) : null}

          {/* <ListItem button onClick={() => routeMe("/")}>
            <ListItemIcon>
              <HomeIcon />
            </ListItemIcon>
            <ListItemText
              primary={tx("jhpo2", "Home")}
              className={sel === 0 ? classes.activeMenuItem : classes.menuItem}
            />
          </ListItem> */}
          {/* <ListItem button onClick={() => routeMe("/pricing")}>
            <ListItemIcon>
              <PaymentIcon />
            </ListItemIcon>
            <ListItemText
              primary={tx("jhpo3", "Pricing")}
              className={sel === 1 ? classes.activeMenuItem : classes.menuItem}
            />
          </ListItem>
          <ListItem button onClick={() => routeMe("/gift")}>
            <ListItemIcon>
              <RedeemIcon />
            </ListItemIcon>
            <ListItemText
              primary={tx("jhpo4", "As a gift")}
              className={sel === 4 ? classes.activeMenuItem : classes.menuItem}
            />
          </ListItem> */}
          {/* <ListItem button onClick={() => routeMe("/moreinfo")}>
            <ListItemIcon>
              <MoreHorizIcon />
            </ListItemIcon>
            <ListItemText
              primary={tx("jhpo5", "More info")}
              className={sel === 2 ? classes.activeMenuItem : classes.menuItem}
            />
          </ListItem> */}
          <ListItem
            button
            onClick={() => routeMe("/" + props.language + "/support")}
          >
            <ListItemIcon>
              <HelpOutlineIcon />
            </ListItemIcon>
            <ListItemText
              primary={tx("jhpo6", "Support")}
              className={sel === 3 ? classes.activeMenuItem : classes.menuItem}
            />
          </ListItem>
        </List>
        <Divider />
      </Drawer>
    </React.Fragment>
  );
};

export default DrawerMine;
