import React, { useState } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core";
import TermsDialog from "footer/footer.terms";
import Legal from "legal/legal";
import { useHistory } from "react-router";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    footer: {
      width: "100%",
      paddingTop: "20px",
      paddingLeft: "25px",
      paddingRight: "25px",
      borderTop: "1px solid lightgrey",
      fontFamily: "var(--font-normal)",
      fontSize: "16px",
      lineHeight: "145%",
      color: "grey",
      bottom: "15px",
      position: "absolute",
    },
    footerIOS: {
      width: "100%",
      paddingTop: "20px",
      paddingLeft: "25px",
      paddingRight: "25px",
      borderTop: "1px solid lightgrey",
      fontFamily: "var(--font-normal)",
      fontSize: "16px",
      lineHeight: "145%",
      color: "grey",
      bottom: "75px",
      position: "absolute",
    },
    bullets: { width: "100%", textAlign: "center" },
    copyright: {
      marginTop: "5px",
      textAlign: "center",
      fontFamily: "var(--font-normal)",
    },
    bullet: { cursor: "pointer", fontFamily: "var(--font-medium)" },
    barion: { textAlign: "center", marginTop: "8px" },
    constrains: { maxWidth: "550px", display: "inline-block" },
  })
);

const Footer = (props: any) => {
  const classes = useStyles();
  const [termsOpen, setTermsOpen] = useState(false);
  const [termsContent, setTermsContent] = useState<any>(
    <React.Fragment></React.Fragment>
  );

  const history = useHistory();

  const { tx } = props;

  const closeDialog = () => {
    setTermsOpen(false);
  };

  const openPrivacy = () => {
    setTermsContent(<Legal language={props.language} doc="privacy" />);
    setTermsOpen(true);
  };

  const openTerms = () => {
    setTermsContent(<Legal language={props.language} doc="terms" />);
    setTermsOpen(true);
  };

  const openCookies = () => {
    setTermsContent(<Legal language={props.language} doc="cookies" />);
    setTermsOpen(true);
  };

  const openImpress = () => {
    setTermsContent(<Legal language={props.language} doc="impress" />);
    setTermsOpen(true);
  };

  const openMoreInfo = () => {
    history.push("/");
  };

  return (
    <React.Fragment>
      <div className={props.isIOS ? classes.footerIOS : classes.footer}>
        <div className={classes.bullets}>
          <div className={classes.constrains}>
            <span className={classes.bullet} onClick={openPrivacy}>
              {tx("japo2", "Privacy Policy")}
            </span>{" "}
            •{" "}
            <span className={classes.bullet} onClick={openTerms}>
              {tx("japo4", "Terms and Conditions")}
            </span>{" "}
            •{" "}
            <span className={classes.bullet} onClick={openCookies}>
              {tx("japo4acookie", "Cookies")}
            </span>{" "}
            •{" "}
            <span className={classes.bullet} onClick={openImpress}>
              {tx("japo4acookies", "About us")}
            </span>{" "}
            •{" "}
            <span className={classes.bullet} onClick={openMoreInfo}>
              {tx("japo5", "More info")}
            </span>
          </div>
        </div>
        <div className={classes.copyright}>
          © 2022 NetEnglish Solutions OÜ Hungarian PE.
          <br />
          {tx("japo6", "All Rights Reserved.")}
        </div>
        <TermsDialog
          open={termsOpen}
          closeDialog={closeDialog}
          help={termsContent}
          tx={tx}
        />
      </div>
    </React.Fragment>
  );
};

export default Footer;

// {" "}
//            <span className={classes.bullet} onClick={openSubscriptionTerms}>
//              {tx("japo4a", "Subscription Terms")}
//            </span>{" "}
//            •
