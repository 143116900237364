import React from "react";
import { allLanguages } from "../utils/languages";
import {
  makeStyles,
  Theme,
  createStyles,
  Menu,
  ListItem,
} from "@material-ui/core";
import { useLocation, useHistory } from "react-router-dom";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    flex: {
      display: "flex",
      alignItems: "center",
      paddingLeft: "0px",
      cursor: "pointer",
    },
    flag: {
      marginRight: "8px",
    },
    name: { fontSize: "18px", fontFamily: "var(--font-medium)" },
    img: {},
  })
);

const LanguageMenu = (props: any) => {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();

  const chooseLanguage = (language: string) => {
    const tempParts = location.pathname.split("/");
    props.setOpen(false);
    history.push("/" + language + "/" + (tempParts[2] || ""));
  };

  const closeMe: any = () => {
    props.setOpen(false);
  };

  return (
    <React.Fragment>
      <Menu
        open={props.open}
        anchorEl={props.anchor}
        disableAutoFocusItem={true}
        onClose={closeMe}
      >
        {allLanguages.map((lang: any) => (
          <ListItem onClick={() => chooseLanguage(lang.code)} key={lang.code}>
            <div className={classes.flex}>
              <div className={classes.flag}>
                <img
                  alt={"flag"}
                  src={`/flags/${lang.code}.png`}
                  height="22px"
                  className={classes.img}
                />
              </div>
              <div className={classes.name}>{lang.ownName}</div>
            </div>
          </ListItem>
        ))}
      </Menu>
    </React.Fragment>
  );
};

export default LanguageMenu;
