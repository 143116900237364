import ReactHtmlParser from "html-react-parser";

// import store from "Redux/store";
// import { decodedStr } from "./utils";

export const tx = (
  uuid: string,
  orig: string = "",
  avar: string = "",
  bvar: string = ""
) => {
  // orig = store.getState().appTransParts[uuid || "bibibi"] || orig;
  // orig = orig.replace(/xxx/g, avar);
  // orig = orig.replace(/yyy/g, bvar);
  // return decodedStr(orig);
  return orig;
};

export const prepareFormat = (html: string) => {
  html = html.replace(/\*(.*?)\*/g, "<span class='colored'>$1</span>");
  html = html.replace(/_(.*?)_/g, "<span class='italic'>$1</span>");
  html = html.replace(/\{(.*?)\}/g, "<span class='bold'>$1</span>");
  return ReactHtmlParser(html);
};
