import firebase from "firebase/app";
// Add the Firebase products that you want to use
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";
import "firebase/database";
import "firebase/functions";

// load firebase

const config = {
  apiKey: "AIzaSyBMpDpBCa8nfMDjxfTB0P1uygq3e-6wllo",
  authDomain: "netenglishm.firebaseapp.com",
  databaseURL: "https://netenglishm.firebaseio.com",
  projectId: "netenglishm",
  storageBucket: "netenglishm.appspot.com",
  messagingSenderId: "381928610475",
  appId: "1:381928610475:web:8083562d0d865e0067b5ee",
  measurementId: "G-ZTHR1MTHCZ",
};

firebase.initializeApp(config);
// firebase.app().auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL);

export interface FreeData {
  [key: string]: any;
}

// Create a reference with an initial file path and name

export const cloudStorage = firebase.storage();

// Create a reference from a Google Cloud Storage URI

export const auth = firebase.auth();

export const firedata = firebase.database();

// firebase
//   .firestore()
//   .enablePersistence({ synchronizeTabs: true })
//   .catch(function (err: any) {
//     if (err.code === "failed-precondition") {
//       // Multiple tabs open, persistence can only be enabled
//       // in one tab at a a time.
//       // ...
//     } else if (err.code === "unimplemented") {
//       // The current browser does not support all of the
//       // features required to enable persistence
//       // ...
//     }
//   });

// if (process.env.NODE_ENV === "development") {
//   firebase.functions().useFunctionsEmulator("http://localhost:5001");
// }

export const firestore = firebase.firestore();

export const functions = firebase.app().functions("europe-west2");

// export const functions = firebase.functions();

export default firebase;
