import React, { useEffect, useRef, useState } from "react";
import { makeStyles, Theme, createStyles, ButtonBase } from "@material-ui/core";

import Arguments from "./homepage.arc";
import Patch1 from "./patch1";
import Patch2 from "./patch2";
import Experience from "./homepage.experience";
import Successes from "./homepage.successes";
import MoneyBack from "./homepage.moneyback";
import { phaseIn } from "../utils/screen";
import Init from "./homepage.init";
import HomeVideo from "./homepage.video";
import HomePricing from "./homepage.pricing";
import OrigButton from "./homepage.gobutton";
import HomeFaq from "./homepage.faq";
import Problems from "./homepage.problem";
import NetEnglish from "./homepage.netenglish";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    long: { height: "3000px" },
    morelink1: {
      marginTop: "25px",
      color: "grey",
      fontFamily: "var(--font-normal)",
      fontSize: "18px",
      cursor: "pointer",
      textAlign: "center",
      marginBottom: "80px",
    },

    hero: {
      maxWidth: "var(--max-width)",
      marginLeft: "auto",
      marginRight: "auto",
      display: "flex",
      width: "100%",
      flexWrap: "wrap",
      paddingTop: "50px",
      paddingLeft: "20px",
      paddingRight: "20px",
      paddingBottom: "30px",
    },
    heroLeft: {
      flex: "1 1 100%",
      padding: "10px",
      paddingTop: "40px",
      "@media (min-width: 750px)": {
        flex: "1 1 50%",
      },
    },
    heroRight: {
      flex: "1 1 100%",
      "@media (min-width: 750px)": {
        flex: "1 1 50%",
      },
      marginTop: "40px",
      textAlign: "center",
      position: "relative",
      paddingTop: "15px",
    },
    textHeader: {
      fontFamily: "var(--font-header)",
      fontWeight: "bold",
      fontSize: "25px",
      lineHeight: "150%",
      marginBottom: "40px",
      textAlign: "center",
      "@media (max-width: 340px)": {
        fontSize: "22px",
        marginBottom: "25px",
      },
    },
    heroTop: {
      fontFamily: "var(--font-header)",
      fontWeight: "bold",
      color: "var(--theme-color)",
      fontSize: "52px",
      lineHeight: "150%",
      marginBottom: "50px",
      textAlign: "center",
      marginTop: "50px",
      "@media (max-width: 750px)": {
        fontSize: "39px",
        marginBottom: "30px",
        marginTop: "30px",
      },
      "@media (max-width: 340px)": {
        fontSize: "30px",
        marginBottom: "0px",
        marginTop: "30px",
      },
    },
    textIntro: {
      fontFamily: "var(--font-normal)",
      fontSize: "18px",
      lineHeight: "150%",
      marginTop: "15px",
    },
    img: { margin: "20px" },
    origButton: { textAlign: "center" },
    button: {
      background: "var(--theme-color)",
      color: "white",
      fontFamily: "var(--font-medium)",
      fontSize: "18px",
      lineHeight: "150%",
      padding: "14px 22px",
      textTransform: "uppercase",
      borderRadius: "3px",
      marginTop: "36px",
    },
    summary: {
      background: "#FFF4EB",
      width: "100%",
      minHeight: "200px",
    },
    packageWrapper: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-around",
      flexWrap: "wrap",
      alignItems: "center",
      maxWidth: "var(--max-width)",

      "@media (min-width: 660px)": {
        display: "block",
        marginLeft: "auto",
        marginRight: "auto",
      },
      "@media (min-width: 800px)": {
        display: "flex",
        marginLeft: "auto",
        marginRight: "auto",
      },
    },
    package: {
      flex: "0 0 290px",
      marginLeft: "10px",
      marginRight: "10px",
      marginTop: "20px",
      marginBottom: "10px",
      backgroundImage: 'url("/painted_patch_rect_01.png")',
      backgroundSize: "100% 100%",
      minHeight: "180px",
      width: "290px",
      borderRadius: "5px",
      "@media (min-width: 660px)": {
        marginLeft: "auto",
        marginRight: "auto",
      },
      "@media (min-width: 800px)": {
        width: "240px",
        flex: "0 0 220px",
        marginLeft: "auto",
        marginRight: "auto",
      },
    },
    whyHeader: {
      fontFamily: "var(--font-header)",
      fontWeight: "bold",
      fontSize: "36px",
      lineHeight: 1.5,
      marginBottom: "40px",
    },
    fore: {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%,-50%) scale(0.5)",
      transformOrigin: "50% 50%",
    },
    patch: {
      position: "absolute",
    },
    fabIcon: {
      marginRight: theme.spacing(1),
    },
  })
);

const arg1 = (tx: any) => ({
  title: tx(
    "jkl1asaw",
    "Did you know that with these words you can understand *90% of everyday English*?"
  ),
  t1: tx("jkl2", "Over 300 lessons"),
  e1: tx(
    "jkl3wew",
    "Who said that learning words must be boring and tedious? "
  ),

  t2: tx("jkl4", "One application"),
  e2: tx(
    "jkl5wew",
    "We took the 3000 most important words of English (and an additional 2000 as a bonus) and put them into an extremely practical and playful application, to help you learn them quickly - and having fun in the process."
  ),
});

const arg2 = (tx: any) => ({
  title: tx(
    "jkm1aasdw",
    "A typical English language course will give you *only 1000 new words* at best..."
  ),
  e1: tx(
    "jkm3asd",
    "....and most likely you'll forget most of them after a week or two."
  ),

  e2: tx(
    "jkm5werw",
    "With WizzWords you can learn the complete basic vocabulary of everyday English for a fraction of the price of a 60-80 hours language course."
  ),

  e3: tx(
    "jkm7asd",
    "And the “smart review” function built into the application makes sure that the words you learn stick with you for long years to come."
  ),
});

const arg5 = (tx: any) => ({
  title: tx("jkp2awewe", "This is what you get with *WizzWords:*"),

  t0: tx("jkp3a1", "Texts and stories on five different levels"),
  e0: tx(
    "jkp4a1",
    " - the trick to learning words in an effective way. Vocabulary building on various levels from beginner to intermediate level – so that you can always start and continue learning on the correct level for you."
  ),
  t1: tx("jkp3a10", "Your tailor-made vocabulary building “notebook”"),
  e1: tx(
    "jkp4a10",
    "– that will regularly test you on the words you are studying. You only have to tap on the unknown words in the text to add them to your notebook. The rest is handled by this little wizzard. :)"
  ),

  t2: tx("jkp7a1", "Short stories from everyday life"),
  e2: tx(
    "jkp8a1",
    "As the only useful way to learn words is in context. Everyday topics with selected vocabulary – so that you can learn everyday English as it is spoken by people."
  ),

  t3: tx("jkp7a2", "Lots of audio"),
  e3: tx(
    "jkp8a2",
    "You can also listen to the stories as you’re reading them, and if you don't understand something, you get instant help with the correct, fitting definition and example sentences for each word."
  ),
  t4: tx("jkp7a3", "„Intelligent” word cards"),
  e4: tx(
    "jkp8a3",
    "Spaced repetition based on a scientific method on word cards, to help you truly get a deep understanding of each word"
  ),

  t5: tx("jkp7a4", "Grammar Practice"),
  e5:
    tx(
      "jkp8a4",
      "Practice verb tenses and almost anything with our built-in grammar exercises."
    ) +
    " " +
    tx(
      "jkp8a4asd",
      "You can choose from a list of topics - practice only the most important parts of grammar that people use in actual conversations."
    ),
  t6: tx("jkp7a4we", "Pronunciation Drills"),
  e6:
    tx(
      "jkp8a4asdqq",
      "Practice your English pronunciation and check how good you are."
    ) +
    " " +
    tx(
      "jkp8a4asdws",
      "On Android phones and desktop computers you can compare the way you speak to a real American speaker until you can match it perfectly."
    ),
  t7: tx("jkp7a4op", "Built in Dictionary"),
  e7:
    tx(
      "jkp8a4cwop",
      "Have your English - [yourlang] dictionary at your fingertips."
    ) +
    " " +
    tx(
      "jkp8a4asdop",
      "Find any meanings instantly and add it to your vocabulary list."
    ),
  t8: tx("jkp7a4xx", "And lots more..."),
  e8: tx(
    "jkp8a4ccc",
    "Easy example sentences for every word... Here each word right away... Built in video course from the Voice of America Learning system... Follow your progress on graphs..."
  ),
});

const argVideo = (tx: any) => ({
  title: tx("jkp2awewewe", "What if I have a problem?"),

  t0: tx("jkp3a10c", "One-click help sections"),
  e0: tx(
    "jkp4a10c",
    "The application comes with a detailed help function. Just find the little question mark in a circle on the page and click on it, it will tell you how to use the functions on that page."
  ),
  t1: tx("jkp3a1c", "Introductory Video"),
  e1: tx(
    "jkp4a1aclw",
    "Once you start using the app, you will watch a simple video which will introduce the main functions of the application to help you get off to a good start."
  ),

  t2: tx("jkp5a1c", "Costumer service also on weekends"),
  e2: tx(
    "jkp6a1c",
    "The FAQ (Frequently Asked Questions) option from the menu will give you the answer to most possible problems. But if you have any questions, just write to us on the provided email address, and we will answer you even on weekends."
  ),

  t3: tx("jkp7a2c", "14 day money back guarantee"),
  e3: tx(
    "jkp8a2c",
    "If you're not satisfied with the application for any reason, just let us know within 14 days and we refund your money without any questions"
  ),
});

const isSafari = () => {
  const ua = navigator.userAgent.toLowerCase();
  return ua.indexOf("safari") > -1 && ua.indexOf("chrome") < 0;
};

let loaded = false;

const HomePage = (props: any) => {
  const classes = useStyles();

  const { tx, language } = props;

  useEffect(() => {
    setTimeout(() => phaseIn());
  }, []);

  useEffect(() => {
    if (language && loaded) {
      (videoParentRef.current?.children[0] as any).load();
      (document.getElementById("thisvideo") as any).load();
    }
    if (language) {
      loaded = true;
    }
  }, [language]);

  // const showMore = () => {
  //   phaseOut();
  //   setTimeout(() => history.push("/moreinfo"));
  // };

  const moveToShopping = () => {
    document
      .getElementById("buybutton")
      ?.scrollIntoView({ behavior: "smooth", block: "center" });
  };

  const videoParentRef = useRef<HTMLDivElement>(null);
  const [shouldUseImage, setShouldUseImage] = useState(false);
  useEffect(() => {
    // check if user agent is safari and we have the ref to the container <div />
    if (isSafari() && videoParentRef.current) {
      // obtain reference to the video element
      // @ts-ignore
      const player = videoParentRef.current?.children[0] as any;

      // if the reference to video player has been obtained
      if (player) {
        // set the video attributes using javascript as per the
        // webkit Policy
        player.controls = false;
        player.playsInline = true;
        player.muted = true;
        player.setAttribute("muted", ""); // leave no stones unturned :)
        player.autoplay = true;

        // Let's wait for an event loop tick and be async.
        setTimeout(() => {
          // player.play() might return a promise but it's not guaranteed crossbrowser.
          const promise = player.play();
          // let's play safe to ensure that if we do have a promise
          if (promise.then) {
            promise
              .then(() => {})
              .catch(() => {
                // if promise fails, hide the video and fallback to <img> tag
                // @ts-ignore
                videoParentRef.current.style.display = "none";
                setShouldUseImage(true);
              });
          }
        }, 0);
      }
    }
  }, []);

  return (
    <React.Fragment>
      <div className={classes.hero}>
        <div className={classes.heroTop}>
          {tx(
            "hkl1abasda1",
            "Do you want to increase your vocabulary to speak *better English?*"
          )}
        </div>
        <div className={classes.heroLeft}>
          <div className={classes.textHeader}>
            {tx(
              "hkl2cb1",
              "If you hate trying to memorize words but you’d like to better speak and understand English, this is the easiest solution you will find."
            )}
          </div>
          <div className={classes.textIntro}>
            {tx(
              "hkl2cb1a1",
              "Imagine never again having trouble understanding everyday conversations, YouTube videos or movies…"
            )}
          </div>
          <div className={classes.textIntro}>
            {tx(
              "hkl2cb1a1a",
              "… and being able to express yourself easily in any situation."
            )}
          </div>
          <div className={classes.textIntro}>
            {tx(
              "hkl2cb1a1a5",
              "Try this unique “vocabulary builder” application to learn new words in a fun and easy way - so that you never again forget what you once learned."
            )}
          </div>
          <div className={classes.origButton}>
            <ButtonBase
              ref={props.buttonRef}
              className={classes.button}
              onClick={moveToShopping}
            >
              {tx("hkl3as", "Show me the special offer!")}
            </ButtonBase>
          </div>
        </div>

        {shouldUseImage ? (
          <div className={classes.heroRight}>
            <img
              src={`/images/${language}/SZKV-WEB-HB.mp4`}
              alt="Muted Video"
              data-aos="fade-up"
            />
          </div>
        ) : (
          <div
            className={classes.heroRight}
            ref={videoParentRef}
            dangerouslySetInnerHTML={{
              __html: `
        <video
          style="width: 80%;"
          loop
          muted
          autoplay
          controls
          controlslist="nodownload"
          playsinline
          preload="metadata"
        >
        <source src="/images/${language}/SZKV-WEB-HB.mp4" type="video/mp4" />
        </video>`,
            }}
          />
        )}
      </div>
      {/* <Banner tx={tx} /> */}
      <Arguments
        reverse={true}
        patch={<Patch1 />}
        args={arg1(tx)}
        tx={tx}
        pic={`/images/${language}/multiple.png`}
      />
      <Arguments
        patch={<Patch2 />}
        args={arg2(tx)}
        tx={tx}
        pic={`/images/${language}/pic1.png`}
        skewed={true}
      />
      <OrigButton tx={tx} />
      <Experience tx={tx} />
      {/* <SuccessesSmall tx={tx} /> */}
      <Init patch={<Patch2 />} args={arg5(tx)} tx={tx} language={language} />
      <OrigButton tx={tx} />
      <HomeVideo tx={tx} language={language} />
      <HomePricing tx={tx} language={language} />
      <MoneyBack tx={tx} language={language} />
      <Successes tx={tx} />

      <HomeFaq tx={tx} language={language} />
      <OrigButton tx={tx} language={language} />
      <Problems tx={tx} args={argVideo(tx)} />
      <NetEnglish tx={tx} />
      <OrigButton tx={tx} />
    </React.Fragment>
  );
};

export default HomePage;
