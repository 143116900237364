import React from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    long: { height: "3000px" },
    appPaper: {
      overflow: "hidden auto",
      height: "100vh",
      paddingTop: "50px",
    },
    summary: {
      background: "#eeeeee",
      width: "100%",
      minHeight: "200px",
      paddingBottom: "25px",
      marginBottom: "30px",
    },

    hero: {
      maxWidth: "var(--max-width)",
      marginLeft: "auto",
      marginRight: "auto",
      display: "flex",
      flexDirection: "row",
      width: "100%",
      flexWrap: "wrap",
      paddingTop: "0px",
      paddingLeft: "20px",
      paddingRight: "20px",
      paddingBottom: "40px",
    },
    heroReverse: {
      maxWidth: "var(--max-width)",
      marginLeft: "auto",
      marginRight: "auto",
      display: "flex",
      flexDirection: "row-reverse",
      width: "100%",
      flexWrap: "wrap",
      paddingTop: "0px",
      paddingLeft: "20px",
      paddingRight: "20px",
      paddingBottom: "40px",
    },
    heroLeft: {
      flex: "1 1 100%",
      padding: "10px",
      paddingTop: "0px",
      "@media (min-width: 750px)": {
        flex: "1 1 50%",
      },
    },
    heroRight: {
      flex: "1 1 100%",
      "@media (min-width: 750px)": {
        flex: "1 1 50%",
      },
      textAlign: "center",
      position: "relative",
      minHeight: "350px",
    },
    textHeader: {
      fontFamily: "Kalam, cursive",
      fontSize: "40px",
      lineHeight: 1.5,
      marginBottom: "40px",
    },
    textIntro: {
      fontFamily: "var(--font-normal)",
      fontSize: "18px",
      lineHeight: "150%",
    },
    img: { margin: "20px" },
    video: {
      width: "100%",
      "@media (max-width: 750px)": { width: "100%" },
    },
    videoXWrapper: {
      maxWidth: "var(--max-width)",
      marginLeft: "auto",
      marginRight: "auto",
      lineHeight: 0,
      paddingLeft: "20px",
      paddingRight: "20px",
      marginBottom: "35px",
    },
    videoWrapper: {
      maxWidth: "450px",
      border: "15px solid lightgrey",
      borderRadius: "15px",
      lineHeight: 0,
      marginLeft: "auto",
      marginRight: "auto",
    },
    button: {
      background: "var(--theme-color)",
      color: "white",
      fontFamily: "var(--font-medium)",
      fontSize: "18px",
      lineHeight: "150%",
      padding: "14px 22px",
      textTransform: "uppercase",
      borderRadius: "3px",
      marginTop: "36px",
    },
    whyHeader: {
      fontFamily: "var(--font-header)",
      fontWeight: "bold",
      fontSize: "30px",
      color: "var(--theme-color)",
      lineHeight: 1.5,
      maxWidth: "var(--max-width)",
      marginLeft: "auto",
      marginRight: "auto",
      width: "100%",
      paddingTop: "50px",
      paddingLeft: "20px",
      paddingRight: "20px",
      textAlign: "center",
    },
    subHeader: {
      fontFamily: "var(--font-normal)",
      fontWeight: 700,
      fontSize: "18px",
      color: "black",
      lineHeight: 1.5,
      marginBottom: "30px",
      maxWidth: "450px",
      marginLeft: "auto",
      marginRight: "auto",
      width: "100%",
      paddingTop: "20px",
      paddingLeft: "20px",
      paddingRight: "20px",
      textAlign: "center",
    },
    fore: {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%,-50%) scale(0.75)",
      transformOrigin: "50% 50%",
    },
    patch: {
      position: "absolute",
      transform: "translate(-40%,-50%)",
      top: "50%",
      left: "50%",
    },
    patchReverse: {
      position: "absolute",
      transform: "translate(-50%,-50%)",
      top: "50%",
      left: "50%",
    },
    flex: {
      display: "flex",
      width: "100%",
      marginBottom: "10px",
    },
    flexleft: { flex: "0 0 40px" },
    flexright: { flex: "1 1 auto" },
    smallheader: {
      fontFamily: "var(--font-medium)",
      fontSize: "18px",
      lineHeight: "150%",
      paddingBottom: "5px",
    },
    smalltext: {
      fontFamily: "var(--font-normal)",
      fontSize: "18px",
      lineHeight: "150%",
    },
  })
);

export const HelpPlus = () => {
  return (
    <svg
      className="helpplus"
      version="1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 48 48"
      enable-background="new 0 0 48 48"
    >
      <circle fill="#0a8080" cx="24" cy="24" r="21" />
      <g fill="#fff">
        <rect x="21" y="14" width="6" height="20" />
        <rect x="14" y="21" width="20" height="6" />
      </g>
    </svg>
  );
};

const HomeVideo = (props: any) => {
  const classes = useStyles();

  const { tx, language } = props;

  return (
    <React.Fragment>
      <div
        className={classes.summary}
        style={props.nongrey ? { background: "#ffffff" } : {}}
      >
        <div className={classes.whyHeader}>
          {tx("vidtitle", "Look for yourself")}
        </div>
        <div className={classes.subHeader}>
          {tx(
            "vidtitle1",
            "Watch this video intended for new users where we show a few functions more in detail:"
          )}
        </div>
        <div className={classes.videoXWrapper}>
          <div className={classes.videoWrapper}>
            <video
              className={classes.video}
              id="thisvideo"
              playsInline
              controls
              controlsList="nodownload"
              poster={`/images/${language}/covershot.png`}
            >
              <source
                src={`/images/${language}/SZKV-utmutato-s.mp4`}
                type="video/mp4"
              />
            </video>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default HomeVideo;
