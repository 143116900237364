import React from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    long: { height: "3000px" },
    appPaper: {
      overflow: "hidden auto",
      height: "100vh",
      paddingTop: "50px",
    },
    hero: {
      maxWidth: "var(--max-width)",
      marginLeft: "auto",
      marginRight: "auto",
      display: "flex",
      flexDirection: "row",
      width: "100%",
      flexWrap: "wrap",
      paddingTop: "50px",
      paddingLeft: "20px",
      paddingRight: "20px",
      paddingBottom: "40px",
    },
    heroReverse: {
      maxWidth: "var(--max-width)",
      marginLeft: "auto",
      marginRight: "auto",
      display: "flex",
      flexDirection: "row-reverse",
      width: "100%",
      flexWrap: "wrap",
      paddingTop: "50px",
      paddingLeft: "20px",
      paddingRight: "20px",
      paddingBottom: "0px",
    },
    heroLeft: {
      flex: "1 1 100%",
      padding: "10px",
      paddingTop: "40px",
      "@media (min-width: 750px)": {
        flex: "1 1 50%",
      },
    },
    heroRight: {
      flex: "1 1 100%",
      "@media (min-width: 750px)": {
        flex: "1 1 50%",
      },
      textAlign: "center",
      position: "relative",
      minHeight: "250px",

      webkitPerspective: "911px",
      perspective: "911px",
    },
    heroRightSkewed: {
      flex: "1 1 100%",
      "@media (min-width: 750px)": {
        flex: "1 1 50%",
      },
      textAlign: "center",
      position: "relative",
      minHeight: "350px",

      webkitPerspective: "911px",
      perspective: "911px",
    },
    textHeader: {
      fontFamily: "Kalam, cursive",
      fontSize: "40px",
      lineHeight: 1.5,
      marginBottom: "40px",
    },
    textIntro: {
      fontFamily: "var(--font-normal)",
      fontSize: "18px",
      lineHeight: "150%",
    },
    img: { margin: "20px" },
    button: {
      background: "var(--theme-color)",
      color: "white",
      fontFamily: "var(--font-medium)",
      fontSize: "18px",
      lineHeight: "150%",
      padding: "14px 22px",
      textTransform: "uppercase",
      borderRadius: "3px",
      marginTop: "36px",
    },

    whyHeader: {
      fontFamily: "var(--font-header)",
      fontWeight: "bold",
      fontSize: "30px",
      color: "var(--theme-color)",
      lineHeight: 1.5,
      marginBottom: "40px",
    },
    fore: {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%,-50%) scale(0.85)",
      width: "100%",
      transformOrigin: "50% 50%",
      maxHeight: "500px",
      "@media (min-width: 750px)": {
        transform: "translate(-50%,-50%) scale(0.85)",
      },
    },
    foreSkewed: {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%,-50%) scale(0.75) rotateX(25deg)",
      transformOrigin: "50% 50%",
      maxHeight: "450px",
    },
    patch: {
      position: "absolute",
      transform: "translate(-40%,-50%)",
      top: "50%",
      left: "50%",
    },
    patchReverse: {
      position: "absolute",
      transform: "translate(-50%,-50%)",
      top: "50%",
      left: "50%",
    },
    flex: {
      display: "flex",
      width: "100%",
      marginBottom: "10px",
    },
    flexleft: { flex: "0 0 40px" },
    flexright: { flex: "1 1 auto" },
    smallheader: {
      fontFamily: "var(--font-medium)",
      fontSize: "18px",
      lineHeight: "150%",
      paddingBottom: "5px",
    },
    smalltext: {
      fontFamily: "var(--font-normal)",
      fontSize: "18px",
      lineHeight: "150%",
    },
  })
);

const Arguments = (props: any) => {
  const classes = useStyles();

  return (
    <React.Fragment>
      <div className={props.reverse ? classes.heroReverse : classes.hero}>
        <div className={classes.heroLeft}>
          <div className={classes.whyHeader}>{props.args.title}</div>
          <div className={classes.flex}>
            <div className={classes.flexright}>
              <div className={classes.smalltext}>{props.args.e1}</div>
            </div>
          </div>
          <div className={classes.flex}>
            <div className={classes.flexright}>
              <div className={classes.smalltext}>{props.args.e2}</div>
            </div>
          </div>
          <div className={classes.flex}>
            {props.args.t3 ? (
              <div className={classes.flexright}>
                <div className={classes.smalltext}>{props.args.e3}</div>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
        <div
          className={props.skewed ? classes.heroRightSkewed : classes.heroRight}
        >
          {/* <div className={props.reverse ? classes.patchReverse : classes.patch}>
            {props.patch}
          </div> */}
          <img
            data-aos="fade"
            alt="phone"
            className={props.skewed ? classes.foreSkewed : classes.fore}
            src={props.pic}
          />
        </div>
      </div>
    </React.Fragment>
  );
};

export default Arguments;
