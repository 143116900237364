import React, { useEffect, useState } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core";
import { useHistory, useParams } from "react-router-dom";
import Waiter from "../utils/Waiter";
import { doSale } from "../routing";
import { prices } from "homepage/homepage.pricing";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    buttonDiv: { marginTop: "20px", textAlign: "right" },

    button: {
      background: "var(--theme-color)",
      color: "white",
      fontFamily: "var(--font-medium)",
      fontSize: "18px",
      lineHeight: "125%",
      padding: "14px 22px",
      textTransform: "uppercase",
      borderRadius: "3px",
    },
    bigtitle: {
      paddingBottom: "20px",
      color: "var(--theme-color)",
      fontSize: "21px",
      fontFamily: "var(--font-bold)",
    },
    text: {
      paddingBottom: "10px",
      fontSize: "18px",
      fontFamily: "var(--font-normal)",
      lineHeight: "135%",
      flex: "1 1 auto",
    },
    backdrop: {
      zIndex: 1800,
      color: "var(--theme-color)",
    },
  })
);

const GiftPending = (props: any) => {
  const history = useHistory();
  const [backdropOpen] = useState(true);
  const { tx } = props;
  const [done, setDone] = useState(false);

  const { lang } = useParams() as any;

  useEffect(() => {
    document.getElementById("appPaper")?.scrollTo(0, 0);

    if (lang && !done) {
      // try {
      //   doSale({
      //     value: prices[lang].price,
      //     currency: prices[lang].currency,
      //     qty: 1,
      //     SKU: "GXGCMEW21S" + lang.toUpperCase(),
      //     name: "NetEnglish App " + lang.toUpperCase(),
      //   });
      // } catch (e: any) {}
      history.push("/" + lang + "/success/");
      setDone(true);
      return;
    } else {
      history.push("/");
    }
  }, [history, done, lang]);

  return (
    <React.Fragment>
      <Waiter
        text={tx("paypageea", "Your payment is pending...")}
        backdropOpen={backdropOpen}
      />
    </React.Fragment>
  );
};

export default GiftPending;
