import React from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core";
import AppMenu from "./header.menu";

import { useHistory } from "react-router-dom";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    headerWrapper: {
      userSelect: "none",
      background: "white",
      position: "fixed",
      top: 0,
      left: 0,
      right: 0,
      height: "53px",
      overflow: "visible",
      zIndex: 800,
      "@media (min-width: 600px)": {
        height: "60px",
      },
    },
    logoBig: {
      transform: "scale(1.5)",
      width: "100%",
      transformOrigin: "0% 0%",
      transition: "all 0.4s 0.3s",
      marginTop: "4px",
      marginLeft: "4px",
      cursor: "pointer",
    },
    logoSmall: {
      transform: "scale(1)",
      width: "100%",
      transformOrigin: "0% 0%",
      transition: "all 0.4s",
      cursor: "pointer",
    },
    img: {
      height: "49px",
      marginTop: "2px",
      marginLeft: "5px",
      "@media (min-width: 600px)": {
        marginTop: "5px",
      },
    },
    lineWrapper: {
      position: "absolute",
      bottom: 0,
      width: "100%",
      textAlign: "center",
    },
    lineSmall: {
      width: "100%",
      height: "1px",
      background: "#cccccc",
      transition: "all 0.3s 0.4s",
    },
    lineBig: {
      width: "0px",
      height: "1px",
      background: "#cccccc",
      transition: "all 0.3s",
    },
    spacer: { height: "50px" },
  })
);

const AppHeader = (props: any) => {
  const classes = useStyles();
  const history = useHistory();
  const language = localStorage.getItem("language");

  return (
    <React.Fragment>
      <div className={classes.headerWrapper}>
        <div
          className={!props.trigger ? classes.logoBig : classes.logoSmall}
          onClick={() => history.push("/" + language)}
        >
          <img
            className={classes.img}
            src={props.language === "hu" ? "/netlogohu.png" : "/netlogo1.png"}
            alt="WizzWords"
          />
        </div>
        <AppMenu
          tx={props.tx}
          language={props.language}
          setLanguage={props.setLanguage}
          bla={props.bla}
        />
        <div className={classes.lineWrapper}>
          <div
            className={!props.trigger ? classes.lineBig : classes.lineSmall}
          />
        </div>
      </div>
    </React.Fragment>
  );
};

export default AppHeader;
