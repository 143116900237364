import "./homepage.css";

import React, { useState } from "react";
import Countdown from "react-countdown";
import { useHistory } from "react-router";

import { createStyles, makeStyles, Theme } from "@material-ui/core";
import BuyDialog from "./homepage.email";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tcbFlexCol: {
      color: "#43454b",
      fontFamily:
        '"Source Sans Pro","HelveticaNeue-Light","Helvetica Neue Light","Helvetica Neue",Helvetica,Arial,"Lucida Grande",sans-serif',
      lineHeight: 1.618,
      fontWeight: 400,
      webkitBoxDirection: "normal",
      paddingTop: 0,
      boxSizing: "border-box",
      paddingLeft: "22px",
      width: "100%",
      maxWidth: "370px",
      minWidth: "310px",
      webkitBoxFlex: 1,
      flex: "1 0 250px",
      position: "relative",
      marginBottom: "40px",
      marginTop: "55px",
    },
    notSubscription: {
      width: "230px",
      margin: "auto",
      fontWeight: 500,
      textAlign: "center",
      marginBottom: "13px",
    },
    countDown: {
      marginTop: "15px",
      marginBottom: "17px",
    },
    contentBackground: {
      position: "absolute",
      width: "100%",
      height: "100% !important",
      left: 0,
      top: 0,
      overflow: "hidden",
      backgroundClip: "padding-box",
      borderRadius: "60px 0px",
      border: "2px solid rgb(231, 102, 89) !important",
      boxShadow: "0 10px 24px 0 rgba(0,0,0,.15)",
      backgroundColor: "#fff",
      zIndex: -4,
    },
    cardContent: {
      display: "inline-block",
      verticalAlign: "middle",
      clear: "both",
      overflow: "visible",
      width: "100%",
      zIndex: 1,
      position: "relative",
      minHeight: "10px",
    },
    cardDiscount: {
      fontSize: "30px !important",
      color: "rgb(231, 102, 89) !important",
      lineHeight: "1.2em !important",
      paddingBottom: "0px !important",
      marginBottom: "0px !important",
      fontFamily: "var(--font-bold)",
      fontWeight: 400,
      width: "100%",
      textAlign: "center",
      marginTop: "25px",
      "@media (max-width: 340px)": {
        fontSize: "25px !important",
      },
    },
    untilOffer: {
      fontSize: "18px !important",
      color: "#1c1c1c",
      lineHeight: "1.2em !important",
      paddingBottom: "0px !important",
      marginBottom: "0px !important",
      fontFamily: "var(--font-bold)",
      fontWeight: 400,
      width: "100%",
      textAlign: "center",
      marginTop: "20px",
    },
    hrLine: {
      paddingTop: "20px",
      paddingBottom: "20px",
      margin: "0",
      maxWidth: "30%",
      marginLeft: "auto",
      marginRight: "auto",
    },
    prices: { textAlign: "center", fontSize: "26px" },
    priceNow: {
      fontSize: "60px !important",
      lineHeight: "0.9em !important",
      paddingTop: "0px !important",
      marginTop: "0px !important",
      marginBottom: "30px",
    },
    wrapper: {
      maxWidth: "var(--max-width)",
      marginLeft: "auto",
      marginRight: "auto",
      paddingLeft: "15px",
      paddingRight: "15px",
      marginBottom: "50px",
      marginTop: "65px",
    },
    pricesWrapper: {
      display: "flex",
      flexWrap: "wrap",
      justifyContent: "center",
      alignItems: "flex-start",
    },
    productData: {
      minWidth: "310px",
      maxWidth: "420px",
      marginTop: "60px",
      marginLeft: "70px",
      fontSize: "18px",
      "@media (max-width: 700px)": {
        marginLeft: "25px",
        marginTop: "30px",
      },
    },
    bullet: {
      background: "url('/check.svg') no-repeat left top",
      backgroundPositionY: "33px",
      paddingLeft: "23px",
      paddingTop: "28px",
    },
    topTitle: {
      color: "var(--theme-color)",
      fontFamily: "var(--font-header)",
      fontWeight: "bold",
      fontSize: "40px",
      textAlign: "center",
      lineHeight: "130%",
      marginBottom: "40px",
      "@media (max-width: 750px)": {
        fontSize: "28px",
      },
    },
    oldPrice: {
      textDecoration: "line-through",
      color: "#e76659",
    },
    subTitle: {
      fontSize: "21px",
      textAlign: "center",
      maxWidth: "700px",
      marginLeft: "auto",
      marginRight: "auto",
      fontFamily: "var(--font-normal)",
      lineHeight: "150%",
    },
    button: {
      background: "var(--theme-color)",
      color: "white",
      fontFamily: "var(--font-medium)",
      fontSize: "18px",
      lineHeight: "125%",
      padding: "14px 22px",
      textTransform: "uppercase",
      borderRadius: "3px",
      border: "none",
      minWidth: "200px",
    },
    clock: {
      display: "flex",
      width: "70%",
      marginTop: "15px",
      marginBottom: "25px",
      marginLeft: "auto",
      marginRight: "auto",
      lineHeight: "1.1",
    },
    digits: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      flex: "1 1 auto",
    },
    number: { fontSize: "30px", color: "black" },
    timeType: {
      fontSize: "13px",
      color: "#666",
      paddingTop: "5px",
      borderTop: "2px solid #e76659",
      minWidth: "35px",
      textAlign: "center",
    },
    mback: {
      textAlign: "center",
      fontSize: "16px",
      marginTop: "10px",
      marginBottom: "28px",
    },
  })
);

export const prices: any = {
  hu: {
    full: "23 800 Ft",
    discount: "9 950 Ft",
    price: 9950,
    currency: "HUF",
    language: "hu",
    code: "GXGCMEW21SHU",
    signature:
      "daebe0f53757984152fb6d4532630ce98007c248687eeb42ad84f514badaf3cd",
  },
  en: {
    full: "23 800 Ft",
    discount: "12 900 Ft",
    price: 12900,
    currency: "HUF",
  },
  es: { full: "€78", discount: "€39", price: 39, currency: "EUR" },
  it: {
    full: "€58",
    discount: "€29",
    price: 29,
    currency: "EUR",
    language: "it",
    signature:
      "27ecb815e3316c8b4d303fd3c6bbd99a9a1aa847fbbf45bd9863573ced1523a0",

    code: "GXGCMEW21SIT",
  },
  pl: {
    full: "319,98 zl",
    discount: "159,99 zl",
    price: 159.99,
    currency: "PLN",
    language: "pl",
    signature:
      "1c54b53795fb99fb77d0b621974e70702d206ed545fd7fb093881fe9f3fa12d6",
    code: "GXGCMEW21SPL",
  },
};

var dM = new Date();
dM.setHours(24, 0, 0, 0); // next midnight

var dEnd = dM.valueOf();

const buyItNow = (lang: any, email: string = "") => {
  console.log({
    //product: 771005,
    product: lang === "hu" ? 639738 : 771005,
    passthrough:
      localStorage.getItem("ref") ||
      (lang === "hu" ? "35017" : "") ||
      "911458f0-f79e-438f-b2ab-4da64764f00c",
    allowQuantity: false,
    email: email,
  });

  //@ts-ignore
  Paddle.Checkout.open({
    //product: 771005,
    product: lang === "hu" ? 639738 : 771005,
    passthrough:
      localStorage.getItem("ref") ||
      (lang === "hu" ? "35017" : "") ||
      "911458f0-f79e-438f-b2ab-4da64764f00c",
    allowQuantity: false,
    email: email,
  });

  // const TwoCoInlineCart = window.TwoCoInlineCart;

  // TwoCoInlineCart.setup.setMerchant("250631471495"); // your Merchant code

  // TwoCoInlineCart.cart.setReset(true);

  // TwoCoInlineCart.products.removeAll();

  // TwoCoInlineCart.products.add({
  //   code: bobj.code,
  //   quantity: 1,
  // }); // add products to cart

  // TwoCoInlineCart.cart.setCartLockedFlag(true);

  // TwoCoInlineCart.cart.setCurrency(bobj.currency);

  // TwoCoInlineCart.cart.setLanguage(bobj.language);

  // TwoCoInlineCart.cart.setSource(localStorage.getItem("ref"));

  // //@ts-ignore
  // if (localStorage.getItem("b1") === "5") {
  //   TwoCoInlineCart.cart.setTest(true);
  // } else {
  //   TwoCoInlineCart.cart.setTest(true);
  // }

  // TwoCoInlineCart.cart.setReturnMethod({
  //   type: "redirect",
  //   url: "https://netenglish.com/" + bobj.language + "/pending",
  // });

  // TwoCoInlineCart.cart.setSignature(bobj.signature);

  // TwoCoInlineCart.cart.checkout(); // start checkout process

  //eyJhbGciOiJIUzUxMiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIyNTA2MzE0NzE0OTUiLCJpYXQiOjE1MTYyMzkwMjJ9.MwTZ82oPvxoJOrzecpU_3DNK9TkzlOuUtodulaWA4d4tBRg-ZsRivZ1vJm5tdOXcVxE3lFgcDG27jbQnQgkvaA
};

const HomePricing = (props: any) => {
  const classes = useStyles();
  const history = useHistory();
  const [email, setEmail] = useState("");
  const [buyOpen, setBuyOpen] = useState(false);

  const { tx, language } = props;

  const renderer = ({ hours, minutes, seconds, completed }: any) => {
    const days = "00";
    hours = hours < 10 ? (hours = "0" + hours) : hours;
    minutes = minutes < 10 ? (minutes = "0" + minutes) : minutes;
    seconds = seconds < 10 ? (seconds = "0" + seconds) : seconds;

    return (
      <div className={classes.clock}>
        <div className={classes.digits}>
          <div className={classes.number}>{days}</div>
          <div className={classes.timeType}>{tx("xdays", "day")}</div>
        </div>
        <div className={classes.digits}>
          <div className={classes.number}>{":"}</div>
          <div></div>
        </div>
        <div className={classes.digits}>
          <div className={classes.number}>{hours}</div>
          <div className={classes.timeType}>{tx("xhours", "hours")}</div>
        </div>
        <div className={classes.digits}>
          <div className={classes.number}>{":"}</div>
          <div></div>
        </div>
        <div className={classes.digits}>
          <div className={classes.number}>{minutes}</div>
          <div className={classes.timeType}>{tx("xmin", "min.")}</div>
        </div>
        <div className={classes.digits}>
          <div className={classes.number}>{":"}</div>
          <div></div>
        </div>
        <div className={classes.digits}>
          <div className={classes.number}>{seconds}</div>
          <div className={classes.timeType}>{tx("xseconds", "secs")}</div>
        </div>
      </div>
    );
  };

  const buyThis = () => {
    // phaseOut();
    switch (language) {
      case "hu":
        buyItNow("hu", email);
        // document.location.href =
        //   "https://secure.2checkout.com/checkout/buy?merchant=250631471495&return-url=https%3A%2F%2Fnetenglish.com%2Fpending%2F%3Flang%3Dhu&return-type=redirect&style=default5fe47968b13ed&tpl=default&prod=GXGCMEW21SHU&qty=1&signature=2eddfbc4e145e6a972ec25f20265dc93e3aad12e1dbcbded7653239681f68191&language=hu&empty-cart=1&test=1";
        return;
      case "es":
        buyItNow(prices[language]);
        document.location.href =
          "https://secure.2checkout.com/checkout/buy?merchant=250631471495&currency=EUR&return-url=https%3A%2F%2Fnetenglish.com%2Fes%2Fpending&return-type=redirect&style=default5fe47968b13ed&tpl=default&prod=GXGCMEW21SES&qty=1&signature=2125dfa370b16b9eb6f6c7a5b634709608161b4bde504e8f3f48715907e473e4&language=es&empty-cart=1";
        return;
      case "pl":
        buyItNow(prices[language]);
        // document.location.href =
        //   "https://secure.2checkout.com/checkout/buy?merchant=250631471495&currency=PLN&return-url=https%3A%2F%2Fnetenglish.com%2Fpl%2Fpending&return-type=redirect&style=default5fe47968b13ed&tpl=default&prod=GXGCMEW21SPL&qty=1&signature=912a602d31df5511697368dacf99b9fee17a4b1db2d74f5db81f2ea9a669265d&language=pl&empty-cart=1";
        return;
      case "it":
        buyItNow("it", email);
        // document.location.href =
        //   "https://secure.2checkout.com/checkout/buy?merchant=250631471495&currency=EUR&return-url=https%3A%2F%2Fnetenglish.com%2Fit%2Fpending%2F&return-type=redirect&style=default5fe47968b13ed&tpl=default&prod=GXGCMEW21SIT&qty=1&signature=b4666a7e7883c7a5d995ce6163b669656e9512bbb4ed176aeddc69ebaa4722ed&language=it&empty-cart=1";
        return;
      default:
        alert("bom");
    }

    setTimeout(() => history.push("/checkout"), 150);
  };

  return (
    <React.Fragment>
      <div className={classes.wrapper}>
        <div className={classes.topTitle}>
          {tx("oowow1", "Special Introductory Offer:")}
          <br />
          {tx("oowow1544", "Get WizzWords at a huge discount!")}
        </div>
        <div className={classes.subTitle}>
          {tx(
            "oowow2",
            "IMPORTANT: For this price you receive WizzWords for unlimited use, this is not a subscription."
          )}{" "}
          {tx(
            "oowow2cas",
            "You can use it on your phone, on your computer or on your tablet at the same time, and you will receive all future updates at no extra cost as well."
          )}
        </div>
        <div className={classes.pricesWrapper}>
          <div className={classes.tcbFlexCol} id="buybutton">
            <div className="tcb-col">
              <div className="tcb-clear" data-css="tve-u-1764f3ca82d">
                <div
                  className="thrv_wrapper thrv_contentbox_shortcode thrv-content-box tcb-pt-cb-wrapper tcb-pt-featured-box"
                  data-css="tve-u-175ff7c8569"
                >
                  <div className={classes.contentBackground}></div>
                  <div className={classes.cardContent}>
                    <div className={classes.cardDiscount}>
                      <strong>{tx("pricedisc", "DISCOUNTED PRICE")}</strong>
                    </div>
                    <div className={classes.untilOffer}>
                      <strong>
                        {tx("priceonly", "Only for a limited time!")}
                      </strong>
                    </div>
                    {/* <div className={classes.countDown}>
                      <Countdown date={dEnd} autoStart renderer={renderer} />
                    </div> */}
                    <div className={classes.notSubscription}>
                      {tx(
                        "alxxxl1",
                        "Not a subscription. Lifetime access and updates."
                      )}
                    </div>
                    {/* <div className={classes.hrLine}>
                      <hr className="tve_sep tve_sep-1" />
                    </div> */}
                    <div className={classes.prices}>
                      {/* <div
                        data-css="tve-u-175ff9f571f"
                        style={{ color: "rgb(231, 102, 89)" }}
                      >
                        <span
                          data-css="tve-u-175ff9f5721"
                          style={{
                            display: "inline-block",
                            textDecoration: "line-through",
                            fontSize: "26px",
                          }}
                        >
                          {prices[language]?.full}
                        </span>{" "}
                      </div> */}

                      <div className={classes.priceNow}>
                        {prices[language]?.discount}
                      </div>
                    </div>

                    <div style={{ textAlign: "center" }}>
                      <button
                        className={classes.button}
                        onClick={() => setBuyOpen(true)}
                      >
                        <span>{tx("ibuyit", "ORDER")}</span>
                      </button>
                    </div>
                    <div className={classes.mback}>
                      {tx("lxxxl4", "14-day money back guarantee")}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className={classes.productData}>
            <div className={classes.bullet}>
              {tx("lxxxl1", "3000 most common English words ")}
            </div>
            <div className={classes.bullet}>
              {tx("lxxxl2", "+2000 bonus words and definitions")}
            </div>
            <div className={classes.bullet}>
              {tx("lxxxl3", "Tailor-made vocabulary builder")}
            </div>
            <div className={classes.bullet}>
              {tx("lxxxl5", "Talking word- and phrase cards")}
            </div>
            <div className={classes.bullet}>
              {tx("lxxxl6", "Stories for 5 different levels")}
            </div>
            <div className={classes.bullet}>
              {tx("lxxxl7", "Lots of audio with native speakers")}
            </div>
            <div className={classes.bullet}>
              {tx("lxxxl8", "Listening excercises")}
            </div>
            <div className={classes.bullet}>
              {tx("lxxxl9", "Videos with transcripts")}
            </div>
            <div className={classes.bullet}>
              {tx("lxxxl9as", "Each word defined")}
            </div>
          </div>
        </div>
      </div>
      <BuyDialog
        open={buyOpen}
        setOpen={setBuyOpen}
        email={email}
        setEmail={setEmail}
        language={language}
        buyThis={buyThis}
        tx={tx}
      />
    </React.Fragment>
  );
};

export default HomePricing;
