import React from "react";
import { ButtonBase } from "@material-ui/core";
import { makeStyles, Theme, createStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    origButton: { textAlign: "center" },
    button: {
      background: "var(--theme-color)",
      color: "white",
      fontFamily: "var(--font-medium)",
      fontSize: "18px",
      lineHeight: "150%",
      padding: "14px 22px",
      textTransform: "uppercase",
      borderRadius: "3px",
      marginTop: "30px",
      marginBottom: "65px",
    },
  })
);

const OrigButton = (props: any) => {
  const classes = useStyles();

  const moveToShopping = () => {
    document
      .getElementById("buybutton")
      ?.scrollIntoView({ behavior: "smooth", block: "center" });
  };

  return (
    <React.Fragment>
      <div className={classes.origButton}>
        <ButtonBase className={classes.button} onClick={moveToShopping}>
          {props.tx("hkl3as", "Show me the special offer!")}
        </ButtonBase>
      </div>
    </React.Fragment>
  );
};

export default OrigButton;
